import { IntlShape } from 'react-intl';

import { FormikErrors } from 'formik';

import { FieldCommand } from 'model/field';


export type FieldErrors = FormikErrors<FieldCommand>;

export function fieldValidator(intl: IntlShape, values: FieldCommand): FieldErrors {
  const _t = intl.formatMessage;

  const errors: FieldErrors = {};

  if (!values.customerId) {
    errors.customerId = _t({ id: 'validation.required' });
  }
  if (!values.name) {
    errors.name = _t({ id: 'validation.required' });
  }
  if (!values.region) {
    errors.region = _t({ id: 'validation.required' });
  }
  if (!values.size) {
    errors.size = _t({ id: 'validation.required' });
  }
  return errors;
}
