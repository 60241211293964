import React from 'react';

// Localization
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

// Material UI
import { createStyles, WithStyles } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

// Model
import { PageRequest, PageResult, Sorting } from 'model/response';
import { Sample, SampleQuery } from 'model/sample';

const styles = (theme: Theme) => createStyles({
  root: {
    padding: 5,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing(3, 0, 2, 2),
    borderRadius: 0,
    textTransform: 'none',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    maxWidth: 150,
  },
});

interface SampleFiltersProps extends WithStyles<typeof styles> {
  intl: IntlShape,
  query: SampleQuery,
  setFilter: (query: Partial<SampleQuery>) => void,
  resetFilter: () => void,
  find: (
    pageRequest?: PageRequest, sorting?: Sorting[]
  ) => Promise<PageResult<Sample>>,
  create: () => void,
  disabled: boolean,
}

class SampleFilters extends React.Component<SampleFiltersProps> {

  constructor(props: SampleFiltersProps) {
    super(props);

    this.clear = this.clear.bind(this);
    this.search = this.search.bind(this);
    this.create = this.create.bind(this);
  }

  clear(): void {
    this.props.resetFilter();
    this.props.find({ page: 0, size: 10 });
  }

  search(e: React.FormEvent): void {
    e.preventDefault();

    this.props.find({ page: 0, size: 10 });
  }

  create(): void {
    this.props.create();
  }

  render() {
    const { classes, disabled, query, setFilter } = this.props;
    const _t = this.props.intl.formatMessage;

    return (
      <form onSubmit={this.search} noValidate autoComplete="off">
        <Grid container spacing={3} justify={'space-between'}>
          <Grid item sm={3} xs={12}>
            <TextField
              fullWidth
              id="customer"
              label={_t({ id: 'sample.manager.filter.customer.name' })}
              variant="standard"
              margin="normal"
              className={classes.textField}
              value={query.customer || ''}
              onChange={e => setFilter({ customer: e.target.value })}
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <TextField
              fullWidth
              id="field"
              label={_t({ id: 'sample.manager.filter.field.name' })}
              variant="standard"
              margin="normal"
              className={classes.textField}
              value={query.field || ''}
              onChange={e => setFilter({ field: e.target.value })}
            />
          </Grid>

          <Grid container item sm={6} xs={12} justify={'flex-end'}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={disabled}
            >
              <FormattedMessage id="view.shared.action.search" />
            </Button>
            <Button
              type="submit"
              variant="contained"
              className={classes.button}
              onClick={this.create}
              disabled={disabled}
            >
              <FormattedMessage id="view.shared.action.create" />
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

// Apply styles
const styledComponent = withStyles(styles)(SampleFilters);

// Inject i18n resources
const localizedComponent = injectIntl(styledComponent);

export default localizedComponent;

