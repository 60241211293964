import React from 'react';

/**
 * Libraries
 */
import * as pathToRegexp from 'path-to-regexp';

/**
 * Components
 */

/**
 * Icons
 */

import Icon from '@mdi/react';

import {
  mdiFolderNetworkOutline,
  mdiAccountMultiple,
  mdiViewDashboardOutline,
  mdiBadgeAccountOutline,
  mdiCogOutline,
  mdiMapOutline,
  mdiDomain,
  mdiSproutOutline,
  mdiBeakerOutline,
} from '@mdi/js';

/**
 * Model
 */
import { RootState } from 'store';

import { EnumRole } from './role';
import { iconFunc } from './types';


/**
 * Routes for utility pages
 */
const Login = '/login';
const ResetPassword = '/reset-password';

export const Pages = {
  Login,
  ResetPassword,
};

/**
 * Static routes
 */

const Dashboard = '/dashboard';
const FieldManager = '/fields';
const FileSystem = '/files';
const Map = '/map';
const OrganizationManager = '/admin/organizations';
const Profile = '/profile';
const Settings = '/settings';
const AccountManager = '/admin/users';
const SampleManager = '/samples';

export const StaticRoutes = {
  Dashboard,
  FieldManager,
  FileSystem,
  Map,
  OrganizationManager,
  Profile,
  Settings,
  AccountManager,
  SampleManager,
};

/**
 * Dynamic routes
 */

const AccountCreate = '/admin/users/record/create';
const AccountCreateForOrganization = '/admin/users/record/create/:organizationId';
const AccountUpdate = '/admin/users/record/update/:id';
const FieldCreate = '/fields/record/create';
const FieldCreateForUser = '/fields/record/create/:userId';
const FieldUpdate = '/fields/record/update/:id';
const OrganizationCreate = '/admin/organizations/record/create';
const OrganizationUpdate = '/admin/organizations/record/update/:id';
const SampleCreate = '/samples/record/create';
const SampleCreateForField = '/samples/record/create/:fieldId';
const SampleUpdate = '/samples/record/update/:id';

export const DynamicRoutes = {
  AccountCreate,
  AccountCreateForOrganization,
  AccountUpdate,
  FieldCreate,
  FieldCreateForUser,
  FieldUpdate,
  OrganizationCreate,
  OrganizationUpdate,
  SampleCreate,
  SampleCreateForField,
  SampleUpdate,
};

/**
 * Routes for error pages
 */

const Forbidden = '/error/403';
const NotFound = '/error/404';

export const ErrorPages = {
  Forbidden,
  NotFound,
};

/**
 * Default links
 */
const defaultLinks = [Dashboard];

interface Route {
  breadcrumb?: boolean;
  description: string;
  icon?: iconFunc;
  title?: string;
  defaultTitle?: string;
  links?: string[];
  roles?: ((roles: EnumRole[], state: RootState) => boolean) | EnumRole[];
  toolbarComponent?: () => React.ReactChild;
  params?: string[];
}

interface RouteRegistry {
  [route: string]: Route;
}

const routes: RouteRegistry = {
  // Pages
  [Login]: {
    description: 'Login to workbench application',
  },
  [ResetPassword]: {
    description: 'Reset user password',
  },
  // Static
  [Dashboard]: {
    icon: (className?: string) => (<Icon path={mdiViewDashboardOutline} size="1.5rem" className={className} />),
    description: 'Initial page',
    title: 'links.dashboard',
    defaultTitle: 'Dashboard',
    links: [FileSystem]
  },
  [FieldManager]: {
    icon: (className?: string) => (<Icon path={mdiSproutOutline} size="1.5rem" className={className} />),
    description: 'Fields',
    title: 'links.field.explorer',
    defaultTitle: 'Fields',
    links: [SampleManager]
  },
  [SampleManager]: {
    icon: (className?: string) => (<Icon path={mdiBeakerOutline} size="1.5rem" className={className} />),
    description: 'Samples',
    title: 'links.sample.explorer',
    defaultTitle: 'Samples',
    links: [FieldManager]
  },
  [FileSystem]: {
    icon: (className?: string) => (<Icon path={mdiFolderNetworkOutline} size="1.5rem" className={className} />),
    description: 'My Files',
    title: 'links.file-system',
    defaultTitle: 'My Files',
    links: [Dashboard]
  },
  [Map]: {
    icon: (className?: string) => (<Icon path={mdiMapOutline} size="1.5rem" className={className} />),
    description: 'Map',
    title: 'links.map',
    defaultTitle: 'Map',
    links: [Dashboard, FieldManager]
  },
  [OrganizationManager]: {
    icon: (className?: string) => (<Icon path={mdiDomain} size="1.5rem" className={className} />),
    description: 'Browse organizations',
    title: 'links.organization.explorer',
    defaultTitle: 'Organization Explorer',
    links: [Dashboard]
  },
  [Profile]: {
    icon: (className?: string) => (<Icon path={mdiBadgeAccountOutline} size="1.5rem" className={className} />),
    description: 'Profile',
    title: 'links.profile',
    defaultTitle: 'Profile',
    links: defaultLinks
  },
  [Settings]: {
    icon: (className?: string) => (<Icon path={mdiCogOutline} size="1.5rem" className={className} />),
    description: 'Settings',
    title: 'links.settings',
    defaultTitle: 'Settings',
    links: defaultLinks
  },
  [AccountManager]: {
    icon: (className?: string) => (<Icon path={mdiAccountMultiple} size="1.5rem" className={className} />),
    description: 'Manage user accounts',
    title: 'links.account.explorer',
    defaultTitle: 'User Management',
    roles: [EnumRole.ADMIN_SYSTEM],
    links: [Dashboard],
  },
  // Dynamic
  [AccountCreate]: {
    description: 'Create new account record',
    title: 'links.account.create',
    defaultTitle: 'Create Account',
    roles: [EnumRole.ADMIN_SYSTEM],
    links: defaultLinks,
  },
  [AccountCreateForOrganization]: {
    description: 'Create new account record',
    title: 'links.account.create',
    defaultTitle: 'Create Account',
    roles: [EnumRole.ADMIN_SYSTEM],
    links: defaultLinks,
    breadcrumb: false,
  },
  [AccountUpdate]: {
    description: 'Update account record',
    title: 'links.account.update',
    defaultTitle: 'Update Account',
    roles: [EnumRole.ADMIN_SYSTEM],
    links: defaultLinks,
  },
  [FieldCreate]: {
    description: 'Create new field record',
    title: 'links.field.create',
    defaultTitle: 'Create Field',
    roles: [EnumRole.USER, EnumRole.ADMIN_SYSTEM],
    links: defaultLinks,
  },
  [FieldCreateForUser]: {
    description: 'Create new field record',
    title: 'links.field.create',
    defaultTitle: 'Create Field',
    roles: [EnumRole.USER, EnumRole.ADMIN_SYSTEM],
    links: defaultLinks,
    breadcrumb: false,
  },
  [FieldUpdate]: {
    description: 'Update field record',
    title: 'links.field.update',
    defaultTitle: 'Update Field',
    roles: [EnumRole.USER, EnumRole.ADMIN_SYSTEM],
    links: defaultLinks,
  },
  [OrganizationCreate]: {
    description: 'Create new organization record',
    title: 'links.organization.create',
    defaultTitle: 'Create Organization',
    roles: [EnumRole.ADMIN_SYSTEM],
    links: defaultLinks,
  },
  [OrganizationUpdate]: {
    description: 'Update organization record',
    title: 'links.organization.update',
    defaultTitle: 'Update Organization',
    roles: [EnumRole.ADMIN_SYSTEM],
    links: defaultLinks,
  },
  [SampleCreate]: {
    description: 'Create new sample record',
    title: 'links.sample.create',
    defaultTitle: 'Create Sample',
    roles: [EnumRole.USER, EnumRole.ADMIN_SYSTEM],
    links: defaultLinks,
  },
  [SampleCreateForField]: {
    description: 'Create new sample record',
    title: 'links.sample.create',
    defaultTitle: 'Create Sample',
    roles: [EnumRole.USER, EnumRole.ADMIN_SYSTEM],
    links: defaultLinks,
    breadcrumb: false,
  },
  [SampleUpdate]: {
    description: 'Update sample record',
    title: 'links.sample.update',
    defaultTitle: 'Update Sample',
    roles: [EnumRole.USER, EnumRole.ADMIN_SYSTEM],
    links: defaultLinks,
  },
  // Error Pages
  [Forbidden]: {
    description: 'Forbidden',
  },
  [NotFound]: {
    description: 'Not Found',
  },
};

export interface RouteMatch {
  path: string,
  route: string,
  properties: Route,
  params: string[],
}
/**
 * Matches the given path to an existing route and returns the route or null
 * if no match is found
 *
 * @export
 * @param {any} path - the route path to match
 * @returns an object with the initial path, the route that matched the given path, the route properties
 * and an array of the parameters. If no match if found, null is returned
 */
export function matchRoute(path: string): RouteMatch | null {
  for (let route in routes) {
    let re = pathToRegexp.pathToRegexp(route);
    const result = re.exec(path);
    if (result) {
      return {
        path,
        route,
        properties: routes[route],
        params: result.splice(1, result.length),
      };
    }
  }

  return null;
}


/**
 * Find a route by its path e.g. /Dashboard
 *
 * @export
 * @param {string} path - the route path
 * @returns the route properties
 */
export function getRoute(path: string): Route | null {
  const match = matchRoute(path);

  if (match) {
    return {
      ...routes[match.route],
      params: match.params,
    };
  }
  return null;
}

/**
 * Build a path given a route and optional parameters
 *
 * @export
 * @param {string} path - The route name
 * @param {string[]|object} params - Optional parameters to bind
 */
export function buildPath(path: string, params: string[] | { [key: string]: string }) {
  let result = path || '/';

  if (params) {
    if (Array.isArray(params)) {
      let re = /:\w+/i;
      for (const value of params) {
        result = result.replace(re, value);
      }
    } else {
      let toPath = pathToRegexp.compile(path);
      result = toPath(params);
    }
  }
  return result;
}
