import { PageResult, Sorting, ObjectResponse } from 'model/response';
import { Sample, SampleQuery } from 'model/sample';

import {
  SampleActions,
  SET_PAGER,
  RESET_PAGER,
  SET_FILTER,
  RESET_FILTER,
  SEARCH_INIT,
  SEARCH_COMPLETE,
  ADD_SELECTED,
  REMOVE_SELECTED,
  SET_SORTING,
  RESET_SELECTED,
  SAVE_INIT,
  SAVE_COMPLETE,
} from './types';


// Action Creators
export function setPager(page: number, size: number): SampleActions {
  return {
    type: SET_PAGER,
    page,
    size,
  };
}

export function resetPager(): SampleActions {
  return {
    type: RESET_PAGER,
  };
}

export function setFilter(query: Partial<SampleQuery>): SampleActions {
  return {
    type: SET_FILTER,
    query,
  };
}

export function resetFilter(): SampleActions {
  return {
    type: RESET_FILTER,
  };
}

export function setSorting(sorting: Sorting[]): SampleActions {
  return {
    type: SET_SORTING,
    sorting,
  };
}

export function searchInit(): SampleActions {
  return {
    type: SEARCH_INIT,
  };
}

export function searchComplete(result: PageResult<Sample>): SampleActions {
  return {
    type: SEARCH_COMPLETE,
    result,
  };
}

export function saveInit(): SampleActions {
  return {
    type: SAVE_INIT,
  };
}

export function saveComplete(response: ObjectResponse<Sample>): SampleActions {
  return {
    type: SAVE_COMPLETE,
    response,
  };
}

export function addToSelection(selected: Sample[]): SampleActions {
  return {
    type: ADD_SELECTED,
    selected,
  };
}

export function removeFromSelection(removed: Sample[]): SampleActions {
  return {
    type: REMOVE_SELECTED,
    removed,
  };
}

export function resetSelection(): SampleActions {
  return {
    type: RESET_SELECTED,
  };
}
