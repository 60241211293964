import { AxiosRequestConfig } from 'axios';

import { Api } from 'utils/api';
import { ObjectResponse, PageRequest, Sorting, AxiosObjectResponse, AxiosPageResponse, PageResult, AxiosSimpleResponse } from 'model/response';
import { Sample, SampleQuery, SampleFormData, SampleCommand } from 'model/sample';
import { Field } from 'model/field';

export default class SampleApi extends Api {

  constructor(config: AxiosRequestConfig = {}) {
    super(config);
  }

  public createNew(fieldId: number | null = null): SampleCommand {
    return {
      activeCaCo3: null,
      b: null,
      caMeq: null,
      caPpm: null,
      cd: null,
      clay: null,
      cu: null,
      ec: null,
      fc: null,
      fe: null,
      fieldId,
      granulometryClass: '',
      groundColorChroma: null,
      groundColorHue: null,
      groundColorValue: null,
      indexAggregatesStability: null,
      indexDrainage: null,
      indexEarthwormPresence: null,
      indexFilteringGround: null,
      indexGranulometryClass: null,
      indexIncline: null,
      indexOrganicMaterial: null,
      indexPhGround: null,
      indexSalinity: null,
      indexStructureGround: null,
      ir: null,
      kmeq: null,
      kppm: null,
      lime: null,
      mgCmol: null,
      mgPpm: null,
      mn: null,
      mo: null,
      naMeq: null,
      naPpm: null,
      nnh4: null,
      nno3: null,
      organicMaterial: null,
      ph: null,
      pppm: null,
      rew: null,
      rhmin: null,
      salts: null,
      sand: null,
      sludge: null,
      totalCaCo3: null,
      totalCarbon: null,
      totalNitrogen: null,
      vwc: null,
      wp: null,
      wsavg: null,
      ze: null,
      zn: null,
      latitude: null,
      longitude: null,
      createdAt: null,
      modifiedAt: null,
      cultivation: null,
      cultivationAge: 0,
      fertilizerBeforeOneYear: '',
      fertilizerBeforeTwoYears: '',
      greenhouse: false,
      incline: null,
      landUse: null,
      performance: '',
      samplingDate: null,
      samplingDepth: null,
      samplingFromCustomer: false,
      variety: '',
      watered: false,
    };
  }

  public async findFieldById(id: number): Promise<AxiosObjectResponse<Field | null>> {
    const url = `/action/samples/field/${id}`;

    return this.get<ObjectResponse<Field | null>>(url);
  }

  public async find(query: Partial<SampleQuery>, pageRequest: PageRequest, sorting: Sorting[]): Promise<AxiosPageResponse<Sample>> {
    const { page, size } = pageRequest;
    const { id: sample, order } = sorting[0];

    const queryString = (Object.keys(query) as Array<keyof SampleQuery>)
      .reduce((result: string[], key: keyof SampleQuery) => {
        return query[key] !== null ? [...result, `${key}=${query[key]}`] : result;
      }, []);

    const url = `/action/samples?page=${page}&size=${size}&${queryString.join('&')}&orderBy=${sample}&order=${order}`;

    return this.get<ObjectResponse<PageResult<Sample>>>(url);
  }

  public async findOne(id: number): Promise<AxiosObjectResponse<SampleFormData>> {
    const url = `/action/samples/${id}`;


    return this.get<ObjectResponse<SampleFormData>>(url);
  }

  public async create(command: SampleCommand): Promise<AxiosObjectResponse<Sample>> {
    const url = `/action/samples/`;

    return this.post<SampleCommand, ObjectResponse<Sample>>(url, command);
  }

  public async update(id: number, command: SampleCommand): Promise<AxiosObjectResponse<Sample>> {
    const url = `/action/samples/${id}`;

    return this.post<SampleCommand, ObjectResponse<Sample>>(url, command);
  }

  public async remove(id: number): Promise<AxiosSimpleResponse> {
    const url = `/action/samples/${id}`;

    return this.delete(url);
  }

  public sampleToCommand(sample: Sample): SampleCommand {
    const { field, incline, cultivation, samplingDepth, landUse, granulometryClass, ...rest } = sample;

    const command: SampleCommand = {
      ...rest,
      cultivation: cultivation?.id || null,
      fieldId: field?.id || null,
      granulometryClass: granulometryClass?.name || null,
      incline: incline?.id || null,
      landUse: landUse?.id || null,
      samplingDepth: samplingDepth?.id || null,
    };

    return command;
  }
}
