import React from 'react';

import { AxiosError } from 'axios';

import moment from 'utils/moment-localized';

// State, routing and localization
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

// 3rd party components
import { createStyles, WithStyles } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { Field, Form, Formik } from 'formik';

import { TextField } from 'formik-material-ui';
import { Checkbox } from 'formik-material-ui';

// Handle Moment dates for material UI pickers
import DateFnsUtils from '@date-io/moment';

// Icons
import Icon from '@mdi/react';
import { mdiTrashCan, mdiUndoVariant, mdiCommentAlertOutline, mdiFlaskOutline, mdiMapMarkerOutline, mdiSproutOutline } from '@mdi/js';

// OpenLayers
import { MapBrowserEvent, Map } from 'ol';
import { toLonLat, fromLonLat } from 'ol/proj';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';
import Circle from 'ol/style/Circle';
import Feature from 'ol/Feature';
import GeoJSON, { GeoJSONGeometry } from 'ol/format/GeoJSON';

// Utilities
import { localizeErrorCodes } from 'utils/error';
import { sampleValidator as validator } from 'validation/sample';

// Services
import message from 'service/message';
import FieldApi from 'service/field';
import SampleApi from 'service/sample';

// Store
import { RootState } from 'store';

// Components
import SecureContent from 'components/secure-content';
import Dialog, { DialogAction, EnumDialogAction } from 'components/dialog';
import AsyncAutoCompleteFormik from 'components/editors/async-autocomplete-formik';
import AutoCompleteFormik from 'components/editors/autocomplete-formik';
import DatePickerFormik from 'components/editors/datepicker-formik';
import NumberFormik from 'components/editors/number-formik';
import OpenLayers from 'components/map';
import RouteGuard from 'components/route-guard';
import TabPanel from 'components/tab-panel';

// Model
import { FieldMapperFunc } from 'utils/error';
import { StaticRoutes } from 'model/routes';
import { EnumRole } from 'model/role';
import { AxiosObjectResponse, SimpleResponse, AxiosPageResponse } from 'model/response';
import { GranulometryClass, Incline, SamplingDepth, Cultivation, LandUse } from 'model/shared';
import { SampleFormData, SampleCommand } from 'model/sample';
import { Field as FieldRecord, FieldBase } from 'model/field';

type SetFieldValueFunc = (field: string, value: any, shouldValidate?: boolean) => void;

const sampleMapper: FieldMapperFunc = (sample: string): string | null => {
  switch (sample) {
    case 'fieldId':
      return 'sample.form.field.field.name';
    case 'granulometryClass':
      return 'sample.form.field.granulometry-class';
  }
  return null;
};

const styles = (theme: Theme) => createStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
  },
  card: {
    padding: theme.spacing(0),
    margin: theme.spacing(1),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    borderRadius: 0,
  },
  cardHeader: {
    padding: theme.spacing(1, 1),
    fontSize: '1rem',
  },
  cardContent: {
    padding: theme.spacing(1, 1),
  },
  form: {
    width: '100%',
    maxWidth: '1024px',
  },
  item: {
    padding: theme.spacing(0, 1, 1, 1),
  },
  button: {
    margin: theme.spacing(3, 1, 2),
    borderRadius: 0,
    textTransform: 'none',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  passwordStrength: {
    position: 'absolute',
    right: '8px',
    top: '32px',
  },
  tabsRoot: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    minHeight: 300,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: 245,
  },
  tabLabel: {
    textTransform: 'none',
    '& span': {
      alignItems: 'flex-start',
      fontSize: 12,
    }
  }
});

const geometryToFeature = (geometryObject: GeoJSONGeometry | null, id = -1) => {
  if (!geometryObject) {
    return null;
  }

  const format = new GeoJSON();

  const geometry = format.readGeometry(geometryObject, {
    dataProjection: 'EPSG:4326',
    featureProjection: 'EPSG:3857',
  });

  const feature = new Feature({
    id,
    geometry,
  });

  return feature;
};

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const geojsonStyle = new Style({
  image: new Circle({
    radius: 5,
    stroke: new Stroke({
      color: [27, 94, 32, 0.8],
      width: 3
    }),
    fill: new Fill({
      color: [46, 125, 50, 0.5],
    }),
  }),
  stroke: new Stroke({
    color: [27, 94, 32, 0.8],
    width: 3
  }),
  fill: new Fill({
    color: [46, 125, 50, 0.5],
  })
});

interface SampleFormState {
  confirm: boolean;
  confirmOnNavigate: boolean;
  fields: FieldBase[];
  fieldFeature: Feature | null;
  fieldLabel: string;
  sample: SampleCommand | null;
  tabIndex: number;
}

interface RouteParams {
  id?: string | undefined;
  fieldId?: string | undefined;
}

interface SampleFormProps extends PropsFromRedux, WithStyles<typeof styles>, RouteComponentProps<RouteParams> {
  intl: IntlShape,
}

class SampleForm extends React.Component<SampleFormProps, SampleFormState> {

  private fieldApi: FieldApi;

  private sampleApi: SampleApi;

  constructor(props: SampleFormProps) {
    super(props);

    this.fieldApi = new FieldApi();
    this.sampleApi = new SampleApi();
  }

  state: SampleFormState = {
    confirm: false,
    confirmOnNavigate: true,
    fields: [],
    fieldFeature: null,
    fieldLabel: '',
    sample: null,
    tabIndex: 0,
  }

  get id(): number | null {
    const { id } = this.props.match.params;

    if (!id) {
      return null;
    }

    return Number.parseInt(id);
  }

  get fieldId(): number | null {
    const { fieldId } = this.props.match.params;

    if (!fieldId) {
      return null;
    }

    return Number.parseInt(fieldId);
  }

  discardChanges(): void {
    this.setState({
      confirmOnNavigate: false,
    }, () => this.props.history.push(StaticRoutes.SampleManager));
  }

  showConfirmDialog(): void {
    this.setState({
      confirm: true,
    });
  }

  hideConfirmDialog(): void {
    this.setState({
      confirm: false,
    });
  }

  confirmDialogHandler(action: DialogAction): void {
    switch (action.key) {
      case EnumDialogAction.Yes:
        this.discardChanges();
        break;
      default:
        this.hideConfirmDialog();
        break;
    }
  }

  onMapClick(e: MapBrowserEvent, map: Map, setFieldValue: SetFieldValueFunc): void {
    if (e.coordinate) {
      const lonLat = toLonLat(e.coordinate);

      setFieldValue('longitude', lonLat[0]);
      setFieldValue('latitude', lonLat[1]);
    }

  }

  componentDidMount() {
    const id = this.id;

    if (id) {
      this.sampleApi.findOne(id).then((response: AxiosObjectResponse<SampleFormData>) => {
        if (response.data.success) {
          const { sample } = response.data.result;
          this.setState({
            fields: sample.field ? [sample.field] : [],
            fieldFeature: sample?.field?.geometry ? geometryToFeature(sample.field.geometry) : null,
            fieldLabel: sample?.field?.name || '',
            sample: this.sampleApi.sampleToCommand(sample),
          });
        } else {
          const messages = localizeErrorCodes(this.props.intl, response.data, false);
          message.errorHtml(messages, () => (<Icon path={mdiCommentAlertOutline} size="3rem" />));

          this.props.history.push(StaticRoutes.SampleManager);
        }
      });
    } else {
      if (this.fieldId) {
        this.sampleApi.findFieldById(this.fieldId).then((response: AxiosObjectResponse<FieldRecord | null>) => {
          const field = response.data.result;

          this.setState({
            fields: field ? [field] : [],
            fieldFeature: field?.geometry ? geometryToFeature(field.geometry) : null,
            fieldLabel: field?.name || '',
            sample: this.sampleApi.createNew(field?.id),
          });
        });
      } else {
        this.setState({
          fields: [],
          sample: this.sampleApi.createNew(),
        });
      }
    }
  }

  onTabChange(tabIndex: number): void {
    this.setState({
      tabIndex,
    });
  };

  render() {
    const _t = this.props.intl.formatMessage;

    const { classes, loading, config: { granulometryClasses, cultivations, inclines, samplingDepths, landUses } } = this.props;
    const { confirmOnNavigate, sample: o, tabIndex } = this.state;

    if (!o) {
      return null;
    }

    return (
      <>
        <Grid container justify="center">
          <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils} locale={'el'}>
            <Formik
              initialValues={{
                ...o,
              }}
              validate={values => {
                const errors = validator(this.props.intl, values);

                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                const id = this.id;

                (id === null ? this.sampleApi.create(values) : this.sampleApi.update(id, values))
                  .then((response) => {
                    if (response.data.success) {
                      this.discardChanges();
                      message.info('error.insert-success');
                    } else {
                      const messages = localizeErrorCodes(this.props.intl, response.data, true, sampleMapper);
                      message.errorHtml(messages, () => (<Icon path={mdiCommentAlertOutline} size="3rem" />), 10000);
                    }
                  })
                  .catch((err: AxiosError<SimpleResponse>) => {
                    const messages = localizeErrorCodes(this.props.intl, err.response?.data, true, sampleMapper);
                    message.errorHtml(messages, () => (<Icon path={mdiCommentAlertOutline} size="3rem" />), 10000);
                  })
                  .finally(() => {
                    setSubmitting(false);
                  });
              }}
            >
              {({ isSubmitting, values, setFieldValue, errors }) => (
                <Form className={classes.form}>
                  <Card className={classes.card}>
                    <CardHeader className={classes.cardHeader}
                      avatar={<Icon path={mdiSproutOutline} size="1.5rem" />}
                      title={_t({ id: 'sample.form.section.main' })}
                    >
                    </CardHeader>
                    <CardContent className={classes.cardContent}>
                      <Grid container item xs={12}>
                        <Grid item xs={12} className={classes.item}>
                          <FormControl fullWidth error={!!errors.fieldId}>
                            <Field
                              component={AsyncAutoCompleteFormik}
                              name="fieldId"
                              getOptionLabel={(option: FieldRecord) => `${option.name} - ${option.customer?.name} - ${option.customer?.vat}`}
                              getOptionKey={(option: FieldRecord) => option.id}
                              noOptionsText={_t({ id: 'view.shared.autocomplete.no-options' })}
                              promptText={_t({ id: 'view.shared.autocomplete.prompt' })}
                              options={this.state.fields}
                              inputProps={{
                                id: 'fieldId',
                                label: _t({ id: 'sample.form.field.field.name' }),
                                error: !!errors.fieldId,
                                onChange: (value: FieldRecord | null): void => {
                                  this.setState({
                                    fieldFeature: value ? geometryToFeature(value.geometry) : null,
                                    fieldLabel: value?.name || '',
                                  });
                                }
                              }}
                              loadOptions={(value: string): Promise<FieldRecord[]> => {
                                if (!value || value.length < 3) {
                                  return Promise.resolve([]);
                                }
                                return this.fieldApi.find({ name: value }, { page: 0, size: 20 }, [{ id: 'name', order: 'asc' }])
                                  .then((response: AxiosPageResponse<FieldRecord>): FieldRecord[] => {
                                    return response.data.result.items;
                                  });
                              }}
                              loadingText={_t({ id: 'view.shared.autocomplete.loading-data' })}
                            >
                              {this.state.fields.map(f => (
                                <MenuItem key={f.id} value={f.id}>{f.name}</MenuItem>
                              ))}
                            </Field>
                            {errors.fieldId &&
                              <FormHelperText>{errors.fieldId}</FormHelperText>
                            }
                          </FormControl>
                        </Grid>

                        <Grid container item xs={4} className={classes.item} alignItems="center">
                          <FormControlLabel
                            control={
                              <Field
                                component={Checkbox}
                                name="watered"
                                type="checkbox"
                                color="primary"
                                inputProps={{
                                  id: 'watered',
                                }}
                              />
                            }
                            label={_t({ id: 'field.form.field.watered' })}
                          />
                        </Grid>
                        <Grid container item xs={4} className={classes.item} alignItems="center">
                          <FormControlLabel
                            control={
                              <Field
                                component={Checkbox}
                                name="greenhouse"
                                type="checkbox"
                                color="primary"
                                inputProps={{
                                  id: 'greenhouse',
                                }}
                              />
                            }
                            label={_t({ id: 'field.form.field.greenhouse' })}
                          />
                        </Grid>
                        <Grid container item xs={4} className={classes.item} alignItems="center">
                          <FormControlLabel
                            control={
                              <Field
                                component={Checkbox}
                                name="samplingFromCustomer"
                                type="checkbox"
                                color="primary"
                                inputProps={{
                                  id: 'samplingFromCustomer',
                                }}
                              />
                            }
                            label={_t({ id: 'field.form.field.samplingFromCustomer' })}
                          />
                        </Grid>
                        <Grid item xs={4} className={classes.item}>
                          <FormControl fullWidth error={!!errors.samplingDate}>
                            <Field
                              component={DatePickerFormik}
                              name="samplingDate"
                              label={_t({ id: 'field.form.field.samplingDate' })}
                              format={'DD/MM/YYYY'}
                              maxDate={moment()}
                              clearable={true}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={4} className={classes.item}>
                          <FormControl fullWidth error={!!errors.incline}>
                            <Field
                              component={AutoCompleteFormik}
                              name="incline"
                              options={inclines}
                              getOptionLabel={(option: Incline) => option.name}
                              getOptionKey={(option: Incline) => option.id}
                              noOptionsText={_t({ id: 'view.shared.autocomplete.no-options' })}
                              inputProps={{
                                id: 'incline',
                                label: _t({ id: 'field.form.field.incline' }),
                                error: !!errors.samplingDepth,
                              }}
                            >
                              {inclines.map(p => (
                                <MenuItem key={p.id} value={p.id}>{p.name}</MenuItem>
                              ))}
                            </Field>
                            {errors.incline &&
                              <FormHelperText>{errors.incline}</FormHelperText>
                            }
                          </FormControl>
                        </Grid>
                        <Grid item xs={4} className={classes.item}>
                          <FormControl fullWidth error={!!errors.samplingDepth}>
                            <Field
                              component={AutoCompleteFormik}
                              name="samplingDepth"
                              options={samplingDepths}
                              getOptionLabel={(option: SamplingDepth) => option.name}
                              getOptionKey={(option: SamplingDepth) => option.id}
                              noOptionsText={_t({ id: 'view.shared.autocomplete.no-options' })}
                              inputProps={{
                                id: 'samplingDepth',
                                label: _t({ id: 'field.form.field.samplingDepth' }),
                                error: !!errors.samplingDepth,
                              }}
                            >
                              {samplingDepths.map(p => (
                                <MenuItem key={p.id} value={p.id}>{p.name}</MenuItem>
                              ))}
                            </Field>
                            {errors.samplingDepth &&
                              <FormHelperText>{errors.samplingDepth}</FormHelperText>
                            }
                          </FormControl>
                        </Grid>
                        <Grid item xs={4} className={classes.item}>
                          <FormControl fullWidth error={!!errors.landUse}>
                            <Field
                              component={AutoCompleteFormik}
                              name="landUse"
                              options={landUses}
                              getOptionLabel={(option: LandUse) => option.name}
                              getOptionKey={(option: LandUse) => option.id}
                              noOptionsText={_t({ id: 'view.shared.autocomplete.no-options' })}
                              inputProps={{
                                id: 'landUse',
                                label: _t({ id: 'field.form.field.landUse' }),
                                error: !!errors.landUse,
                              }}
                            >
                              {landUses.map(p => (
                                <MenuItem key={p.id} value={p.id}>{p.name}</MenuItem>
                              ))}
                            </Field>
                            {errors.landUse &&
                              <FormHelperText>{errors.landUse}</FormHelperText>
                            }
                          </FormControl>
                        </Grid>
                        <Grid item xs={8} className={classes.item}>
                        </Grid>
                        <Grid item xs={4} className={classes.item}>
                          <FormControl fullWidth error={!!errors.cultivation}>
                            <Field
                              component={AutoCompleteFormik}
                              name="cultivation"
                              options={cultivations}
                              getOptionLabel={(option: Cultivation) => option.name}
                              getOptionKey={(option: Cultivation) => option.id}
                              noOptionsText={_t({ id: 'view.shared.autocomplete.no-options' })}
                              inputProps={{
                                id: 'cultivation',
                                label: _t({ id: 'field.form.field.cultivation' }),
                                error: !!errors.cultivation,
                              }}
                            >
                              {cultivations.map(p => (
                                <MenuItem key={p.id} value={p.id}>{p.name}</MenuItem>
                              ))}
                            </Field>
                            {errors.cultivation &&
                              <FormHelperText>{errors.cultivation}</FormHelperText>
                            }
                          </FormControl>
                        </Grid>
                        <Grid item xs={4} className={classes.item}>
                          <Field
                            component={TextField}
                            name="variety"
                            type="text"
                            fullWidth
                            inputProps={{
                              id: 'variety',
                              maxLength: 64,
                            }}
                            label={_t({ id: 'field.form.field.variety' })}
                          />
                        </Grid>
                        <Grid item xs={4} className={classes.item}>
                          <Field
                            component={TextField}
                            name="performance"
                            type="text"
                            fullWidth
                            inputProps={{
                              id: 'performance',
                              maxLength: 64,
                            }}
                            label={_t({ id: 'field.form.field.performance' })}
                          />
                        </Grid>
                        <Grid item xs={4} className={classes.item}>
                          <Field
                            component={TextField}
                            name="cultivationAge"
                            type="number"
                            fullWidth
                            inputProps={{
                              id: 'cultivationAge',
                              maxLength: 64,
                              min: 0,
                            }}
                            label={_t({ id: 'field.form.field.cultivationAge' })}
                          />
                        </Grid>
                        <Grid item xs={4} className={classes.item}>
                          <Field
                            component={TextField}
                            name="fertilizerBeforeOneYear"
                            type="text"
                            fullWidth
                            inputProps={{
                              id: 'fertilizerBeforeOneYear',
                              maxLength: 64,
                            }}
                            label={_t({ id: 'field.form.field.fertilizerBeforeOneYear' })}
                          />
                        </Grid>
                        <Grid item xs={4} className={classes.item}>
                          <Field
                            component={TextField}
                            name="fertilizerBeforeTwoYears"
                            type="text"
                            fullWidth
                            inputProps={{
                              id: 'fertilizerBeforeTwoYears',
                              maxLength: 64,
                            }}
                            label={_t({ id: 'field.form.field.fertilizerBeforeTwoYears' })}
                          />
                        </Grid>
                        <Grid item xs={6} className={classes.item}>
                          <FormControl fullWidth error={!!errors.longitude}>
                            <Field
                              component={NumberFormik}
                              name="longitude"
                              fullWidth
                              inputProps={{
                                id: 'longitude',
                                maxLength: 20,
                                decimalSeparator: ',',
                                decimalScale: 10,
                              }}
                              label={_t({ id: 'sample.form.field.longitude' })}
                            />
                            {errors.longitude &&
                              <FormHelperText>{errors.longitude}</FormHelperText>
                            }
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} className={classes.item}>
                          <FormControl fullWidth error={!!errors.latitude}>
                            <Field
                              component={NumberFormik}
                              name="latitude"
                              fullWidth
                              inputProps={{
                                id: 'latitude',
                                maxLength: 20,
                                decimalSeparator: ',',
                                decimalScale: 10,
                                max: 90,
                                min: -90,
                              }}
                              label={_t({ id: 'sample.form.field.latitude' })}
                            />
                            {errors.latitude &&
                              <FormHelperText>{errors.latitude}</FormHelperText>
                            }
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} className={classes.item}>
                          <OpenLayers.Map
                            center={[2522457.20, 4743383.34]}
                            click={(event, map) => this.onMapClick(event, map, setFieldValue)}
                            extent={[2023227.29, 4023104.66, 3569089.78, 5482314.24]}
                            height={'500px'}
                            maxZoom={18}
                            minZoom={5}
                            zoom={5}
                          >
                            <OpenLayers.Layers>
                              <OpenLayers.Layers>
                                <OpenLayers.Layer.BingMaps
                                  applicationKey={this.props.config.bingMaps?.applicationKey || ''}
                                  imagerySet={this.props.config.bingMaps?.imagerySet || ''}
                                />
                                <OpenLayers.Layer.GeoJSON
                                  features={this.state.fieldFeature ? [this.state.fieldFeature] : []}
                                  fitToExtent={true}
                                  style={geojsonStyle}
                                >
                                </OpenLayers.Layer.GeoJSON>
                              </OpenLayers.Layers>
                            </OpenLayers.Layers>
                            <OpenLayers.GeoLocation
                              autoPan={true}
                              autoZoom={18}
                              positionChange={(position: number[] | undefined) => {
                                if (position) {
                                  const lonLat = toLonLat(position);

                                  setFieldValue('longitude', lonLat[0]);
                                  setFieldValue('latitude', lonLat[1]);
                                }
                              }}
                              positionError={(id: string) => {
                                message.error(id, () => (<Icon path={mdiMapMarkerOutline} size="3rem" />));
                              }}
                              style={{
                                top: 5,
                              }}
                            >
                            </OpenLayers.GeoLocation>
                            <OpenLayers.Overlay
                              id="popup-1"
                              position={values.longitude && values.latitude ? fromLonLat([values.longitude, values.latitude]) : undefined}
                              width={'150px'}
                              closable={false}
                            >
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Icon path={mdiFlaskOutline} size="1.5rem" />
                                <div style={{ marginLeft: 8 }}>
                                  <p>{this.state.fieldLabel}</p>
                                </div>
                              </div>
                            </OpenLayers.Overlay>
                          </OpenLayers.Map>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>

                  <Card className={classes.card}>
                    <CardHeader className={classes.cardHeader}
                      avatar={<Icon path={mdiFlaskOutline} size="1.5rem" />}
                      title={_t({ id: 'sample.form.section.sample' })}
                    >
                    </CardHeader>
                    <CardContent className={classes.cardContent}>
                      <Grid container item xs={12}>
                        <Grid item xs={12} className={classes.item}>
                          <div className={classes.tabsRoot}>
                            <Tabs
                              orientation="vertical"
                              variant="scrollable"
                              value={tabIndex}
                              onChange={(event: React.ChangeEvent<{}>, value: any) => this.onTabChange(value)}
                              aria-label="Vertical tabs example"
                              className={classes.tabs}
                            >
                              <Tab label={_t({ id: 'sample.form.section.ground-analysis' })} {...a11yProps(0)} className={classes.tabLabel} />
                              <Tab label={_t({ id: 'sample.form.section.ground-color' })} {...a11yProps(1)} className={classes.tabLabel} />
                              <Tab label={_t({ id: 'sample.form.section.hydrodynamics' })} {...a11yProps(2)} className={classes.tabLabel} />
                              <Tab label={_t({ id: 'sample.form.section.quality-index' })} {...a11yProps(3)} className={classes.tabLabel} />
                            </Tabs>
                            <TabPanel value={tabIndex} index={0}>
                              <Grid container item xs={12}>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.sand}>
                                    <Field
                                      component={NumberFormik}
                                      name="sand"
                                      fullWidth
                                      inputProps={{
                                        id: 'sand',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 0,
                                        suffix: ' %'
                                      }}
                                      label={_t({ id: 'sample.form.field.sand' })}
                                    />
                                    {errors.sand &&
                                      <FormHelperText>{errors.sand}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.clay}>
                                    <Field
                                      component={NumberFormik}
                                      name="clay"
                                      fullWidth
                                      inputProps={{
                                        id: 'clay',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 0,
                                        suffix: ' %'
                                      }}
                                      label={_t({ id: 'sample.form.field.clay' })}
                                    />
                                    {errors.clay &&
                                      <FormHelperText>{errors.clay}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.sludge}>
                                    <Field
                                      component={NumberFormik}
                                      name="sludge"
                                      fullWidth
                                      inputProps={{
                                        id: 'sludge',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 0,
                                        suffix: ' %'
                                      }}
                                      label={_t({ id: 'sample.form.field.sludge' })}
                                    />
                                    {errors.sludge &&
                                      <FormHelperText>{errors.sludge}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.granulometryClass}>
                                    <Field
                                      component={AutoCompleteFormik}
                                      name="granulometryClass"
                                      options={granulometryClasses}
                                      getOptionLabel={(option: GranulometryClass) => option.name}
                                      getOptionKey={(option: GranulometryClass) => option.name}
                                      noOptionsText={_t({ id: 'view.shared.autocomplete.no-options' })}
                                      inputProps={{
                                        id: 'granulometryClass',
                                        label: _t({ id: 'sample.form.field.granulometry-class' }),
                                        error: !!errors.granulometryClass,
                                      }}
                                    >
                                      {granulometryClasses.map(p => (
                                        <MenuItem key={p.name} value={p.name}>{p.name}</MenuItem>
                                      ))}
                                    </Field>
                                    {errors.granulometryClass &&
                                      <FormHelperText>{errors.granulometryClass}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.ph}>
                                    <Field
                                      component={NumberFormik}
                                      name="ph"
                                      fullWidth
                                      inputProps={{
                                        id: 'ph',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.ph' })}
                                    />
                                    {errors.ph &&
                                      <FormHelperText>{errors.ph}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.ec}>
                                    <Field
                                      component={NumberFormik}
                                      name="ec"
                                      fullWidth
                                      inputProps={{
                                        id: 'ec',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.ec' })}
                                    />
                                    {errors.ec &&
                                      <FormHelperText>{errors.ec}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.salts}>
                                    <Field
                                      component={NumberFormik}
                                      name="salts"
                                      fullWidth
                                      inputProps={{
                                        id: 'salts',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.salts' })}
                                    />
                                    {errors.salts &&
                                      <FormHelperText>{errors.salts}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.totalCaCo3}>
                                    <Field
                                      component={NumberFormik}
                                      name="totalCaCo3"
                                      fullWidth
                                      inputProps={{
                                        id: 'totalCaCo3',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.totalCaCo3' })}
                                    />
                                    {errors.totalCaCo3 &&
                                      <FormHelperText>{errors.totalCaCo3}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.activeCaCo3}>
                                    <Field
                                      component={NumberFormik}
                                      name="activeCaCo3"
                                      fullWidth
                                      inputProps={{
                                        id: 'activeCaCo3',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.activeCaCo3' })}
                                    />
                                    {errors.activeCaCo3 &&
                                      <FormHelperText>{errors.activeCaCo3}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.lime}>
                                    <Field
                                      component={NumberFormik}
                                      name="lime"
                                      fullWidth
                                      inputProps={{
                                        id: 'lime',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.lime' })}
                                    />
                                    {errors.lime &&
                                      <FormHelperText>{errors.lime}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.organicMaterial}>
                                    <Field
                                      component={NumberFormik}
                                      name="organicMaterial"
                                      fullWidth
                                      inputProps={{
                                        id: 'organicMaterial',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.organicMaterial' })}
                                    />
                                    {errors.organicMaterial &&
                                      <FormHelperText>{errors.organicMaterial}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.totalCarbon}>
                                    <Field
                                      component={NumberFormik}
                                      name="totalCarbon"
                                      fullWidth
                                      inputProps={{
                                        id: 'totalCarbon',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.totalCarbon' })}
                                    />
                                    {errors.totalCarbon &&
                                      <FormHelperText>{errors.totalCarbon}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.nno3}>
                                    <Field
                                      component={NumberFormik}
                                      name="nno3"
                                      fullWidth
                                      inputProps={{
                                        id: 'nno3',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.nno3' })}
                                    />
                                    {errors.nno3 &&
                                      <FormHelperText>{errors.nno3}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.nnh4}>
                                    <Field
                                      component={NumberFormik}
                                      name="nnh4"
                                      fullWidth
                                      inputProps={{
                                        id: 'nnh4',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.nnh4' })}
                                    />
                                    {errors.nnh4 &&
                                      <FormHelperText>{errors.nnh4}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.totalNitrogen}>
                                    <Field
                                      component={NumberFormik}
                                      name="totalNitrogen"
                                      fullWidth
                                      inputProps={{
                                        id: 'totalNitrogen',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.totalNitrogen' })}
                                    />
                                    {errors.totalNitrogen &&
                                      <FormHelperText>{errors.totalNitrogen}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.pppm}>
                                    <Field
                                      component={NumberFormik}
                                      name="pppm"
                                      fullWidth
                                      inputProps={{
                                        id: 'pppm',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.pppm' })}
                                    />
                                    {errors.pppm &&
                                      <FormHelperText>{errors.pppm}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.kppm}>
                                    <Field
                                      component={NumberFormik}
                                      name="kppm"
                                      fullWidth
                                      inputProps={{
                                        id: 'kppm',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.kppm' })}
                                    />
                                    {errors.kppm &&
                                      <FormHelperText>{errors.kppm}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.naPpm}>
                                    <Field
                                      component={NumberFormik}
                                      name="naPpm"
                                      fullWidth
                                      inputProps={{
                                        id: 'naPpm',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.naPpm' })}
                                    />
                                    {errors.naPpm &&
                                      <FormHelperText>{errors.naPpm}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.caPpm}>
                                    <Field
                                      component={NumberFormik}
                                      name="caPpm"
                                      fullWidth
                                      inputProps={{
                                        id: 'caPpm',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.caPpm' })}
                                    />
                                    {errors.caPpm &&
                                      <FormHelperText>{errors.caPpm}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.mgPpm}>
                                    <Field
                                      component={NumberFormik}
                                      name="mgPpm"
                                      fullWidth
                                      inputProps={{
                                        id: 'mgPpm',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.mgPpm' })}
                                    />
                                    {errors.mgPpm &&
                                      <FormHelperText>{errors.mgPpm}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.kmeq}>
                                    <Field
                                      component={NumberFormik}
                                      name="kmeq"
                                      fullWidth
                                      inputProps={{
                                        id: 'kmeq',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.kmeq' })}
                                    />
                                    {errors.kmeq &&
                                      <FormHelperText>{errors.kmeq}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.naMeq}>
                                    <Field
                                      component={NumberFormik}
                                      name="naMeq"
                                      fullWidth
                                      inputProps={{
                                        id: 'naMeq',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.naMeq' })}
                                    />
                                    {errors.naMeq &&
                                      <FormHelperText>{errors.naMeq}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.caMeq}>
                                    <Field
                                      component={NumberFormik}
                                      name="caMeq"
                                      fullWidth
                                      inputProps={{
                                        id: 'caMeq',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.caMeq' })}
                                    />
                                    {errors.caMeq &&
                                      <FormHelperText>{errors.caMeq}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.mgCmol}>
                                    <Field
                                      component={NumberFormik}
                                      name="mgCmol"
                                      fullWidth
                                      inputProps={{
                                        id: 'mgCmol',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.mgCmol' })}
                                    />
                                    {errors.mgCmol &&
                                      <FormHelperText>{errors.mgCmol}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.zn}>
                                    <Field
                                      component={NumberFormik}
                                      name="zn"
                                      fullWidth
                                      inputProps={{
                                        id: 'zn',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.zn' })}
                                    />
                                    {errors.zn &&
                                      <FormHelperText>{errors.zn}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.mn}>
                                    <Field
                                      component={NumberFormik}
                                      name="mn"
                                      fullWidth
                                      inputProps={{
                                        id: 'mn',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.mn' })}
                                    />
                                    {errors.mn &&
                                      <FormHelperText>{errors.mn}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.cu}>
                                    <Field
                                      component={NumberFormik}
                                      name="cu"
                                      fullWidth
                                      inputProps={{
                                        id: 'cu',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.cu' })}
                                    />
                                    {errors.cu &&
                                      <FormHelperText>{errors.cu}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.fe}>
                                    <Field
                                      component={NumberFormik}
                                      name="fe"
                                      fullWidth
                                      inputProps={{
                                        id: 'fe',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.fe' })}
                                    />
                                    {errors.fe &&
                                      <FormHelperText>{errors.fe}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.b}>
                                    <Field
                                      component={NumberFormik}
                                      name="b"
                                      fullWidth
                                      inputProps={{
                                        id: 'b',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.b' })}
                                    />
                                    {errors.b &&
                                      <FormHelperText>{errors.b}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.mo}>
                                    <Field
                                      component={NumberFormik}
                                      name="mo"
                                      fullWidth
                                      inputProps={{
                                        id: 'mo',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.mo' })}
                                    />
                                    {errors.mo &&
                                      <FormHelperText>{errors.mo}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.cd}>
                                    <Field
                                      component={NumberFormik}
                                      name="cd"
                                      fullWidth
                                      inputProps={{
                                        id: 'cd',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.cd' })}
                                    />
                                    {errors.cd &&
                                      <FormHelperText>{errors.cd}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </TabPanel>
                            <TabPanel value={tabIndex} index={1}>
                              <Grid container item xs={12}>
                                <Grid item xs={12} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.groundColorHue}>
                                    <Field
                                      component={NumberFormik}
                                      name="groundColorHue"
                                      fullWidth
                                      inputProps={{
                                        id: 'groundColorHue',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.groundColorHue' })}
                                    />
                                    {errors.groundColorHue &&
                                      <FormHelperText>{errors.groundColorHue}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.groundColorValue}>
                                    <Field
                                      component={NumberFormik}
                                      name="groundColorValue"
                                      fullWidth
                                      inputProps={{
                                        id: 'groundColorValue',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.groundColorValue' })}
                                    />
                                    {errors.groundColorValue &&
                                      <FormHelperText>{errors.groundColorValue}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.groundColorChroma}>
                                    <Field
                                      component={NumberFormik}
                                      name="groundColorChroma"
                                      fullWidth
                                      inputProps={{
                                        id: 'groundColorChroma',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.groundColorChroma' })}
                                    />
                                    {errors.groundColorChroma &&
                                      <FormHelperText>{errors.groundColorChroma}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </TabPanel>
                            <TabPanel value={tabIndex} index={2}>
                              <Grid container item xs={12}>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.vwc}>
                                    <Field
                                      component={NumberFormik}
                                      name="vwc"
                                      fullWidth
                                      inputProps={{
                                        id: 'vwc',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.vwc' })}
                                    />
                                    {errors.vwc &&
                                      <FormHelperText>{errors.vwc}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.fc}>
                                    <Field
                                      component={NumberFormik}
                                      name="fc"
                                      fullWidth
                                      inputProps={{
                                        id: 'fc',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.fc' })}
                                    />
                                    {errors.fc &&
                                      <FormHelperText>{errors.fc}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.wp}>
                                    <Field
                                      component={NumberFormik}
                                      name="wp"
                                      fullWidth
                                      inputProps={{
                                        id: 'wp',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.wp' })}
                                    />
                                    {errors.wp &&
                                      <FormHelperText>{errors.wp}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.ze}>
                                    <Field
                                      component={NumberFormik}
                                      name="ze"
                                      fullWidth
                                      inputProps={{
                                        id: 'ze',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.ze' })}
                                    />
                                    {errors.ze &&
                                      <FormHelperText>{errors.ze}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.rew}>
                                    <Field
                                      component={NumberFormik}
                                      name="rew"
                                      fullWidth
                                      inputProps={{
                                        id: 'rew',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.rew' })}
                                    />
                                    {errors.rew &&
                                      <FormHelperText>{errors.rew}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.ir}>
                                    <Field
                                      component={NumberFormik}
                                      name="ir"
                                      fullWidth
                                      inputProps={{
                                        id: 'ir',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.ir' })}
                                    />
                                    {errors.ir &&
                                      <FormHelperText>{errors.ir}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.rhmin}>
                                    <Field
                                      component={NumberFormik}
                                      name="rhmin"
                                      fullWidth
                                      inputProps={{
                                        id: 'rhmin',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.rhmin' })}
                                    />
                                    {errors.rhmin &&
                                      <FormHelperText>{errors.rhmin}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.wsavg}>
                                    <Field
                                      component={NumberFormik}
                                      name="wsavg"
                                      fullWidth
                                      inputProps={{
                                        id: 'wsavg',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.wsavg' })}
                                    />
                                    {errors.wsavg &&
                                      <FormHelperText>{errors.wsavg}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </TabPanel>
                            <TabPanel value={tabIndex} index={3}>
                              <Grid container item xs={12}>
                                <Grid item xs={6} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.indexGranulometryClass}>
                                    <Field
                                      component={NumberFormik}
                                      name="indexGranulometryClass"
                                      fullWidth
                                      inputProps={{
                                        id: 'indexGranulometryClass',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.indexGranulometryClass' })}
                                    />
                                    {errors.indexGranulometryClass &&
                                      <FormHelperText>{errors.indexGranulometryClass}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.indexFilteringGround}>
                                    <Field
                                      component={NumberFormik}
                                      name="indexFilteringGround"
                                      fullWidth
                                      inputProps={{
                                        id: 'indexFilteringGround',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.indexFilteringGround' })}
                                    />
                                    {errors.indexFilteringGround &&
                                      <FormHelperText>{errors.indexFilteringGround}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.indexPhGround}>
                                    <Field
                                      component={NumberFormik}
                                      name="indexPhGround"
                                      fullWidth
                                      inputProps={{
                                        id: 'indexPhGround',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.indexPhGround' })}
                                    />
                                    {errors.indexPhGround &&
                                      <FormHelperText>{errors.indexPhGround}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.indexSalinity}>
                                    <Field
                                      component={NumberFormik}
                                      name="indexSalinity"
                                      fullWidth
                                      inputProps={{
                                        id: 'indexSalinity',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.indexSalinity' })}
                                    />
                                    {errors.indexSalinity &&
                                      <FormHelperText>{errors.indexSalinity}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.indexStructureGround}>
                                    <Field
                                      component={NumberFormik}
                                      name="indexStructureGround"
                                      fullWidth
                                      inputProps={{
                                        id: 'indexStructureGround',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.indexStructureGround' })}
                                    />
                                    {errors.indexStructureGround &&
                                      <FormHelperText>{errors.indexStructureGround}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.indexEarthwormPresence}>
                                    <Field
                                      component={NumberFormik}
                                      name="indexEarthwormPresence"
                                      fullWidth
                                      inputProps={{
                                        id: 'indexEarthwormPresence',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.indexEarthwormPresence' })}
                                    />
                                    {errors.indexEarthwormPresence &&
                                      <FormHelperText>{errors.indexEarthwormPresence}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.indexOrganicMaterial}>
                                    <Field
                                      component={NumberFormik}
                                      name="indexOrganicMaterial"
                                      fullWidth
                                      inputProps={{
                                        id: 'indexOrganicMaterial',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.indexOrganicMaterial' })}
                                    />
                                    {errors.indexOrganicMaterial &&
                                      <FormHelperText>{errors.indexOrganicMaterial}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.indexDrainage}>
                                    <Field
                                      component={NumberFormik}
                                      name="indexDrainage"
                                      fullWidth
                                      inputProps={{
                                        id: 'indexDrainage',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.indexDrainage' })}
                                    />
                                    {errors.indexDrainage &&
                                      <FormHelperText>{errors.indexDrainage}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.indexAggregatesStability}>
                                    <Field
                                      component={NumberFormik}
                                      name="indexAggregatesStability"
                                      fullWidth
                                      inputProps={{
                                        id: 'indexAggregatesStability',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.indexAggregatesStability' })}
                                    />
                                    {errors.indexAggregatesStability &&
                                      <FormHelperText>{errors.indexAggregatesStability}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.indexIncline}>
                                    <Field
                                      component={NumberFormik}
                                      name="indexIncline"
                                      fullWidth
                                      inputProps={{
                                        id: 'indexIncline',
                                        maxLength: 20,
                                        thousandSeparator: '',
                                        decimalSeparator: ',',
                                        decimalScale: 4,
                                      }}
                                      label={_t({ id: 'sample.form.field.indexIncline' })}
                                    />
                                    {errors.indexIncline &&
                                      <FormHelperText>{errors.indexIncline}</FormHelperText>
                                    }
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </TabPanel>
                          </div>
                        </Grid>

                        <Grid container item xs={12} justify="flex-end">
                          <Button
                            type="submit"
                            style={{ marginRight: 10 }}
                            variant="contained"
                            color="primary"
                            className={`${classes.button} mr-2`}
                            disabled={isSubmitting || loading}
                          >
                            <FormattedMessage id="view.shared.action.save" />
                          </Button>
                          <Button
                            type="button"
                            variant="contained"
                            className={classes.button}
                            onClick={() => this.showConfirmDialog()}
                            disabled={isSubmitting || loading}>
                            <FormattedMessage id="view.shared.action.cancel" />
                          </Button>
                        </Grid>
                        <SecureContent roles={[EnumRole.DEVELOPER]}>
                          <pre>{JSON.stringify(values, null, 2)}</pre>
                        </SecureContent>
                        <SecureContent roles={[EnumRole.DEVELOPER]}>
                          <pre>{JSON.stringify(errors, null, 2)}</pre>
                        </SecureContent>
                      </Grid>
                    </CardContent>
                  </Card>
                </Form>
              )}
            </Formik>
          </MuiPickersUtilsProvider>
        </Grid>
        {this.renderConfirm()}
        <RouteGuard
          when={confirmOnNavigate}
          navigate={(location: string): void => this.props.history.push(location)}
        />
      </>
    );
  }

  renderConfirm() {
    const _t = this.props.intl.formatMessage;

    const { confirm } = this.state;

    if (!confirm) {
      return null;
    }

    return (
      <Dialog
        actions={[
          {
            key: EnumDialogAction.Yes,
            label: _t({ id: 'view.shared.action.yes' }),
            iconClass: () => (<Icon path={mdiTrashCan} size="1.5rem" />),
            color: 'primary',
          }, {
            key: EnumDialogAction.No,
            label: _t({ id: 'view.shared.action.no' }),
            iconClass: () => (<Icon path={mdiUndoVariant} size="1.5rem" />)
          }
        ]}
        handleClose={() => this.hideConfirmDialog()}
        handleAction={(action) => this.confirmDialogHandler(action)}
        header={
          <span>
            <i className={'mdi mdi-comment-question-outline mr-2'}></i>
            <FormattedMessage id="view.shared.dialog.title" />
          </span>
        }
        open={confirm}
      >
        <FormattedMessage id="view.shared.message.cancel-confirm" />
      </Dialog>
    );
  }
}

const mapState = (state: RootState) => ({
  config: state.config,
  loading: state.sample.explorer.loading,
  lastUpdated: state.sample.explorer.lastUpdated,
});

const mapDispatch = {
};

const connector = connect(
  mapState,
  mapDispatch,
);

type PropsFromRedux = ConnectedProps<typeof connector>

// Apply styles
const styledComponent = withStyles(styles)(SampleForm);

// Inject i18n resources
const localizedComponent = injectIntl(styledComponent);

// Inject state
export default connector(localizedComponent);
