import { Moment } from 'moment';

import { LogoutInitAction } from 'store/security/types';
import { PageResult, PageRequest, Sorting, ObjectResponse } from 'model/response';
import { Organization, OrganizationQuery } from 'model/organization';

// State
export interface OrganizationManagerState {
  loading: boolean;
  lastUpdated: Moment | null;
  pagination: PageRequest;
  query: OrganizationQuery;
  result: PageResult<Organization> | null;
  selected: Organization[];
  sorting: Sorting[];
  response: ObjectResponse<Organization> | null;
}

// Actions
export const SET_PAGER = 'organization/explorer/SET_PAGER';
export const RESET_PAGER = 'organization/explorer/RESET_PAGER';

export const SET_SORTING = 'organization/explorer/SET_SORTING';

export const SET_FILTER = 'organization/explorer/SET_FILTER';
export const RESET_FILTER = 'organization/explorer/RESET_FILTER';

export const SEARCH_INIT = 'organization/explorer/SEARCH_INIT';
export const SEARCH_COMPLETE = 'organization/explorer/SEARCH_COMPLETE';

export const SAVE_INIT = 'organization/explorer/SAVE_INIT';
export const SAVE_COMPLETE = 'organization/explorer/SAVE_COMPLETE';

export const ADD_SELECTED = 'organization/explorer/ADD_SELECTED';
export const REMOVE_SELECTED = 'organization/explorer/REMOVE_SELECTED';
export const RESET_SELECTED = 'organization/explorer/RESET_SELECTED';


export interface SetPagerAction {
  type: typeof SET_PAGER;
  page: number;
  size: number;
}

export interface ResetPagerAction {
  type: typeof RESET_PAGER
}

export interface SetSortingAction {
  type: typeof SET_SORTING;
  sorting: Sorting[];
}

export interface SetFilterAction {
  type: typeof SET_FILTER;
  query: Partial<OrganizationQuery>;
}

export interface ResetFilterAction {
  type: typeof RESET_FILTER;
}

export interface SearchInitAction {
  type: typeof SEARCH_INIT;
}

export interface SearchCompleteAction {
  type: typeof SEARCH_COMPLETE;
  result: PageResult<Organization>;
}

export interface SaveInitAction {
  type: typeof SAVE_INIT;
}

export interface SaveCompleteAction {
  type: typeof SAVE_COMPLETE;
  response: ObjectResponse<Organization>;
}

export interface SetSelectedAction {
  type: typeof ADD_SELECTED;
  selected: Organization[];
}

export interface RemoveFromSelectionAction {
  type: typeof REMOVE_SELECTED;
  removed: Organization[];
}

export interface ResetSelectionAction {
  type: typeof RESET_SELECTED;
}

export type OrganizationManagerActions =
  | LogoutInitAction
  | SetPagerAction
  | ResetPagerAction
  | SetSortingAction
  | SetFilterAction
  | ResetFilterAction
  | SearchInitAction
  | SearchCompleteAction
  | SetSelectedAction
  | RemoveFromSelectionAction
  | ResetSelectionAction
  | SaveInitAction
  | SaveCompleteAction
  ;
