import React from 'react';

import { AxiosError } from 'axios';

import moment from 'utils/moment-localized';

// State, routing and localization
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

// 3rd party components
import { createStyles, WithStyles } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Field, Form, Formik } from 'formik';

import { TextField } from 'formik-material-ui';
import { Checkbox } from 'formik-material-ui';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// Handle Moment dates for material UI pickers
import DateFnsUtils from '@date-io/moment';

// Icons
import Icon from '@mdi/react';
import { mdiTrashCan, mdiUndoVariant, mdiCommentAlertOutline, mdiTextBoxOutline, mdiFlaskOutline, mdiCommentTextOutline } from '@mdi/js';

// OpenLayers
import { GeoJSONGeometry } from 'ol/format/GeoJSON';

// Utilities
import { localizeErrorCodes } from 'utils/error';
import { fieldValidator as validator } from 'validation/field';

// Services
import message from 'service/message';
import AccountApi from 'service/account';
import FieldApi from 'service/field';

// Store
import { RootState } from 'store';

// Components
import SecureContent from 'components/secure-content';
import Dialog, { DialogAction, EnumDialogAction } from 'components/dialog';
import AsyncAutoCompleteFormik from 'components/editors/async-autocomplete-formik';
import AutoCompleteFormik from 'components/editors/autocomplete-formik';
import DatePickerFormik from 'components/editors/datepicker-formik';
import NumberFormik from 'components/editors/number-formik';
import GeometryEditor from 'components/editors/geometry-editor';
import OpenLayers from 'components/map';
import RouteGuard from 'components/route-guard';

// Model
import { FieldMapperFunc } from 'utils/error';
import { StaticRoutes } from 'model/routes';
import { EnumRole } from 'model/role';
import { AxiosObjectResponse, SimpleResponse, AxiosPageResponse } from 'model/response';
import { Prefecture, MunicipalUnity } from 'model/shared';
import { FieldFormData, FieldCommand } from 'model/field';
import { Account } from 'model/account';
import { CustomerBase } from 'model/customer';

const hidden = true;

const fieldMapper: FieldMapperFunc = (field: string): string | null => {
  switch (field) {
    case 'name':
      return 'field.form.field.name';
    case 'region':
      return 'field.form.field.region';
    case 'customerId':
      return 'field.form.field.customer.name';
    case 'prefecture':
      return 'field.form.field.prefecture';
    case 'incline':
      return 'field.form.field.incline';
    case 'cultivation':
      return 'field.form.field.cultivation';
    case 'samplingDepth':
      return 'field.form.field.samplingDepth';
    case 'landUse':
      return 'field.form.field.landUse';
    case 'municipalUnity':
      return 'field.form.field.municipalUnity';
  }
  return null;
};

const styles = (theme: Theme) => createStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
  },
  card: {
    padding: theme.spacing(0),
    margin: theme.spacing(1),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    borderRadius: 0,
  },
  cardHeader: {
    padding: theme.spacing(1, 1),
    fontSize: '1rem',
  },
  cardContent: {
    padding: theme.spacing(1, 1),
  },
  form: {
    width: '100%',
    maxWidth: '1024px',
  },
  item: {
    padding: 8,
  },
  button: {
    margin: theme.spacing(3, 1, 2),
    borderRadius: 0,
    textTransform: 'none',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  password: {
    position: 'relative',
  },
  passwordStrength: {
    position: 'absolute',
    right: '8px',
    top: '32px',
  }
});

interface FieldState {
  confirm: boolean;
  confirmOnNavigate: boolean;
  customers: CustomerBase[];
  field: FieldCommand | null;
  showExtra: boolean;
}

interface RouteParams {
  id?: string | undefined;
  userId?: string | undefined;
}

interface FieldFormProps extends PropsFromRedux, WithStyles<typeof styles>, RouteComponentProps<RouteParams> {
  intl: IntlShape,
}

class FieldForm extends React.Component<FieldFormProps, FieldState> {

  private accountApi: AccountApi;

  private fieldApi: FieldApi;

  private geometryEditorRef: React.RefObject<GeometryEditor>;

  constructor(props: FieldFormProps) {
    super(props);

    this.accountApi = new AccountApi();
    this.fieldApi = new FieldApi();

    this.geometryEditorRef = React.createRef<GeometryEditor>();
  }


  state: FieldState = {
    confirm: false,
    confirmOnNavigate: true,
    customers: [],
    field: null,
    showExtra: false,
  }

  get id(): number | null {
    const { id } = this.props.match.params;

    if (!id) {
      return null;
    }

    return Number.parseInt(id);
  }

  get userId(): number | null {
    const { userId } = this.props.match.params;

    if (!userId) {
      return null;
    }

    return Number.parseInt(userId);
  }

  discardChanges(): void {
    this.setState({
      confirmOnNavigate: false,
    }, () => this.props.history.push(StaticRoutes.FieldManager));
  }

  showConfirmDialog(): void {
    this.setState({
      confirm: true,
    });
  }

  hideConfirmDialog(): void {
    this.setState({
      confirm: false,
    });
  }

  confirmDialogHandler(action: DialogAction): void {
    switch (action.key) {
      case EnumDialogAction.Yes:
        this.discardChanges();
        break;
      default:
        this.hideConfirmDialog();
        break;
    }
  }

  componentDidMount() {
    const id = this.id;

    if (id) {
      this.fieldApi.findOne(id).then((response: AxiosObjectResponse<FieldFormData>) => {
        if (response.data.success) {
          const { field } = response.data.result;
          this.setState({
            customers: field.customer ? [field.customer] : [],
            field: this.fieldApi.fieldToCommand(field),
          });
        } else {
          const messages = localizeErrorCodes(this.props.intl, response.data, false);
          message.errorHtml(messages, () => (<Icon path={mdiCommentAlertOutline} size="3rem" />));

          this.props.history.push(StaticRoutes.FieldManager);
        }
      });
    } else {
      if (this.userId) {
        this.fieldApi.findCustomerById(this.userId).then((response: AxiosObjectResponse<CustomerBase | null>) => {
          const customer = response.data.result;

          this.setState({
            customers: customer ? [customer] : [],
            field: this.fieldApi.createNew(customer?.id),
          });
        });
      } else {
        this.fieldApi.findCustomers().then((response: AxiosObjectResponse<CustomerBase[]>) => {
          this.setState({
            customers: [],
            field: this.fieldApi.createNew(),
          });
        });
      }
    }
  }

  render() {
    const _t = this.props.intl.formatMessage;

    const { classes, loading, config: { prefectures, municipalUnities } } = this.props;
    const { field: o, confirmOnNavigate } = this.state;

    if (!o) {
      return null;
    }

    return (
      <>
        <Grid container justify="center">
          <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils} locale={'el'}>
            <Formik
              initialValues={{
                ...o,
              }}
              validate={values => {
                const errors = validator(this.props.intl, values);

                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                const id = this.id;

                (id === null ? this.fieldApi.create(values) : this.fieldApi.update(id, values))
                  .then((response) => {
                    if (response.data.success) {
                      this.discardChanges();
                      message.info('error.insert-success');
                    } else {
                      const messages = localizeErrorCodes(this.props.intl, response.data, true, fieldMapper);
                      message.errorHtml(messages, () => (<Icon path={mdiCommentAlertOutline} size="3rem" />), 10000);
                    }
                  })
                  .catch((err: AxiosError<SimpleResponse>) => {
                    const messages = localizeErrorCodes(this.props.intl, err.response?.data, true, fieldMapper);
                    message.errorHtml(messages, () => (<Icon path={mdiCommentAlertOutline} size="3rem" />), 10000);
                  })
                  .finally(() => {
                    setSubmitting(false);
                  });
              }}
            >
              {({ isSubmitting, values, setFieldValue, errors }) => (
                <Form className={classes.form}>
                  <Card className={classes.card}>
                    <CardHeader className={classes.cardHeader}
                      avatar={<Icon path={mdiTextBoxOutline} size="1.5rem" />}
                      title={_t({ id: 'field.form.section.main' })}
                    >
                    </CardHeader>
                    <CardContent className={classes.cardContent}>
                      <Grid container item xs={12}>
                        <Grid item xs={12} className={classes.item}>
                          <FormControl fullWidth error={!!errors.customerId}>
                            <Field
                              component={AsyncAutoCompleteFormik}
                              name="customerId"
                              getOptionLabel={(option: CustomerBase) => `${option.name} - ${option.vat}`}
                              getOptionKey={(option: CustomerBase) => option.id}
                              noOptionsText={_t({ id: 'view.shared.autocomplete.no-options' })}
                              promptText={_t({ id: 'view.shared.autocomplete.prompt' })}
                              options={this.state.customers}
                              inputProps={{
                                id: 'customerId',
                                label: _t({ id: 'field.form.field.customer.name' }),
                                error: !!errors.customerId,
                              }}
                              loadOptions={(value: string): Promise<CustomerBase[]> => {
                                if (!value || value.length < 3) {
                                  return Promise.resolve([]);
                                }
                                return this.accountApi.find({ name: value }, { page: 0, size: 20 }, [{ id: 'name', order: 'asc' }])
                                  .then((response: AxiosPageResponse<Account>): CustomerBase[] => {
                                    const customers = response.data.result.items.map(a => a.customer);

                                    return customers;
                                  });
                              }}
                              loadingText={_t({ id: 'view.shared.autocomplete.loading-data' })}
                            >
                              {this.state.customers.map(o => (
                                <MenuItem key={o.id} value={o.id}>{o.name}</MenuItem>
                              ))}
                            </Field>
                            {errors.customerId &&
                              <FormHelperText>{errors.customerId}</FormHelperText>
                            }
                          </FormControl>
                        </Grid>
                        <Grid item xs={4} className={classes.item}>
                          <Field
                            component={TextField}
                            name="name"
                            type="text"
                            fullWidth
                            inputProps={{
                              id: 'name',
                              maxLength: 64,
                            }}
                            label={_t({ id: 'field.form.field.name' })}
                          />
                        </Grid>
                        <Grid item xs={4} className={classes.item}>
                          <Field
                            component={TextField}
                            name="region"
                            type="text"
                            fullWidth
                            inputProps={{
                              id: 'region',
                              maxLength: 64,
                            }}
                            label={_t({ id: 'field.form.field.region' })}
                          />
                        </Grid>
                        <Grid item xs={4} className={classes.item}>
                          <FormControl fullWidth error={!!errors.municipalUnity}>
                            <Field
                              component={AutoCompleteFormik}
                              name="municipalUnity"
                              options={municipalUnities}
                              getOptionLabel={(option: MunicipalUnity) => option.name}
                              getOptionKey={(option: MunicipalUnity) => option.id}
                              noOptionsText={_t({ id: 'view.shared.autocomplete.no-options' })}
                              inputProps={{
                                id: 'municipalUnity',
                                label: _t({ id: 'field.form.field.municipalUnity' }),
                                error: !!errors.municipalUnity,
                              }}
                            >
                              {municipalUnities.map(p => (
                                <MenuItem key={p.id} value={p.id}>{p.name}</MenuItem>
                              ))}
                            </Field>
                            {errors.municipalUnity &&
                              <FormHelperText>{errors.municipalUnity}</FormHelperText>
                            }
                          </FormControl>
                        </Grid>
                        <Grid item xs={4} className={classes.item}>
                          <Field
                            component={TextField}
                            name="municipality"
                            type="text"
                            inputProps={{
                              id: 'municipality',
                              maxLength: 100,
                            }}
                            label={_t({ id: 'field.form.field.municipality' })}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={4} className={classes.item}>
                          <FormControl fullWidth error={!!errors.prefecture}>
                            <Field
                              component={AutoCompleteFormik}
                              name="prefecture"
                              options={prefectures}
                              getOptionLabel={(option: Prefecture) => option.name}
                              getOptionKey={(option: Prefecture) => option.id}
                              noOptionsText={_t({ id: 'view.shared.autocomplete.no-options' })}
                              inputProps={{
                                id: 'prefecture',
                                label: _t({ id: 'field.form.field.prefecture' }),
                                error: !!errors.prefecture,
                              }}
                            >
                              {prefectures.map(p => (
                                <MenuItem key={p.id} value={p.id}>{p.name}</MenuItem>
                              ))}
                            </Field>
                            {errors.prefecture &&
                              <FormHelperText>{errors.prefecture}</FormHelperText>
                            }
                          </FormControl>
                        </Grid>
                        <Grid item xs={4} className={classes.item}>
                          <FormControl fullWidth error={!!errors.size}>
                            <Field
                              component={NumberFormik}
                              name="size"
                              fullWidth
                              inputProps={{
                                id: 'size',
                                maxLength: 20,
                                thousandSeparator: '.',
                                decimalSeparator: ',',
                                decimalScale: 3,
                              }}
                              label={_t({ id: 'field.form.field.size' })}
                            />
                            {errors.size &&
                              <FormHelperText>{errors.size}</FormHelperText>
                            }
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} className={classes.item}>
                          <GeometryEditor
                            ref={this.geometryEditorRef}
                            geometry={values.geometry}
                            onChange={(geometry: GeoJSONGeometry | null) => {
                              setFieldValue('geometry', geometry);
                            }}
                          >
                            <OpenLayers.Layer.BingMaps
                              applicationKey={this.props.config.bingMaps?.applicationKey || ''}
                              imagerySet={this.props.config.bingMaps?.imagerySet || ''}
                            />
                          </GeometryEditor>
                        </Grid>
                        {this.state.showExtra === false &&
                          <>
                            <Grid container item xs={12} justify="flex-end">
                              <Button
                                type="submit"
                                style={{ marginRight: 10 }}
                                variant="contained"
                                color="primary"
                                className={`${classes.button} mr-2`}
                                disabled={isSubmitting || loading}
                              >
                                <FormattedMessage id="view.shared.action.save" />
                              </Button>
                              <Button
                                type="button"
                                variant="contained"
                                className={classes.button}
                                onClick={() => this.showConfirmDialog()}
                                disabled={isSubmitting || loading}>
                                <FormattedMessage id="view.shared.action.cancel" />
                              </Button>
                            </Grid>
                            <SecureContent roles={[EnumRole.DEVELOPER]}>
                              <pre>{JSON.stringify(values, null, 2)}</pre>
                            </SecureContent>
                            <SecureContent roles={[EnumRole.DEVELOPER]}>
                              <pre>{JSON.stringify(errors, null, 2)}</pre>
                            </SecureContent>
                          </>
                        }
                      </Grid>
                    </CardContent>
                  </Card>

                  {this.state.showExtra &&
                    <>
                      <Grid container>
                        <Grid item xs={6}>
                          <Card className={classes.card}>
                            <CardHeader className={classes.cardHeader}
                              avatar={<Icon path={mdiFlaskOutline} size="1.5rem" />}
                              title={_t({ id: 'field.form.section.manure' })}
                            >
                            </CardHeader>
                            <CardContent className={classes.cardContent}>
                              <Grid container item xs={12}>
                                <Grid item xs={12} className={classes.item}>
                                  <FormControlLabel
                                    control={
                                      <Field
                                        component={Checkbox}
                                        name="manure"
                                        type="checkbox"
                                        color="primary"
                                        inputProps={{
                                          id: 'manure',
                                          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                            const checked = e.target.value === 'true';
                                            if (checked) {
                                              setFieldValue('manureDate', null);
                                              setFieldValue('manureQuantity', '');
                                            }
                                            setFieldValue('manure', !checked);
                                          }
                                        }}
                                      />
                                    }
                                    label={_t({ id: 'field.form.field.manure' })}
                                  />
                                </Grid>
                                <Grid item xs={6} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.manureDate}>
                                    <Field
                                      component={DatePickerFormik}
                                      name="manureDate"
                                      label={_t({ id: 'field.form.field.manureDate' })}
                                      format={'DD/MM/YYYY'}
                                      maxDate={moment()}
                                      clearable={true}
                                      readOnly={!values.manure}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6} className={classes.item}>
                                  <Field
                                    component={TextField}
                                    name="manureQuantity"
                                    type="text"
                                    fullWidth
                                    inputProps={{
                                      id: 'manureQuantity',
                                      maxLength: 64,
                                      readOnly: !values.manure,
                                    }}
                                    label={_t({ id: 'field.form.field.manureQuantity' })}
                                  />
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={6}>
                          <Card className={classes.card}>
                            <CardHeader className={classes.cardHeader}
                              avatar={<Icon path={mdiFlaskOutline} size="1.5rem" />}
                              title={_t({ id: 'field.form.section.organicMaterial' })}
                            >
                            </CardHeader>
                            <CardContent className={classes.cardContent}>
                              <Grid container item xs={12}>
                                <Grid item xs={12} className={classes.item}>
                                  <FormControlLabel
                                    control={
                                      <Field
                                        component={Checkbox}
                                        name="organicMaterial"
                                        type="checkbox"
                                        color="primary"
                                        inputProps={{
                                          id: 'organicMaterial',
                                          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                            const checked = e.target.value === 'true';
                                            if (checked) {
                                              setFieldValue('organicMaterialDate', null);
                                              setFieldValue('organicMaterialQuantity', '');
                                            }
                                            setFieldValue('organicMaterial', !checked);
                                          }
                                        }}
                                      />
                                    }
                                    label={_t({ id: 'field.form.field.organicMaterial' })}
                                  />
                                </Grid>
                                <Grid item xs={6} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.organicMaterialDate}>
                                    <Field
                                      component={DatePickerFormik}
                                      name="organicMaterialDate"
                                      label={_t({ id: 'field.form.field.organicMaterialDate' })}
                                      format={'DD/MM/YYYY'}
                                      maxDate={moment()}
                                      clearable={true}
                                      readOnly={!values.organicMaterial}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6} className={classes.item}>
                                  <Field
                                    component={TextField}
                                    name="organicMaterialQuantity"
                                    type="text"
                                    fullWidth
                                    inputProps={{
                                      id: 'organicMaterialQuantity',
                                      maxLength: 64,
                                      readOnly: !values.organicMaterial,
                                    }}
                                    label={_t({ id: 'field.form.field.organicMaterialQuantity' })}
                                  />
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid item xs={6}>
                          <Card className={classes.card}>
                            <CardHeader className={classes.cardHeader}
                              avatar={<Icon path={mdiFlaskOutline} size="1.5rem" />}
                              title={_t({ id: 'field.form.section.calcification' })}
                            >
                            </CardHeader>
                            <CardContent className={classes.cardContent}>
                              <Grid container item xs={12}>
                                <Grid item xs={12} className={classes.item}>
                                  <FormControlLabel
                                    control={
                                      <Field
                                        component={Checkbox}
                                        name="calcification"
                                        type="checkbox"
                                        color="primary"
                                        inputProps={{
                                          id: 'calcification',
                                          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                            const checked = e.target.value === 'true';
                                            if (checked) {
                                              setFieldValue('calcificationDate', null);
                                              setFieldValue('calcificationQuantity', '');
                                            }
                                            setFieldValue('calcification', !checked);
                                          }
                                        }}
                                      />
                                    }
                                    label={_t({ id: 'field.form.field.calcification' })}
                                  />
                                </Grid>
                                <Grid item xs={6} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.calcificationDate}>
                                    <Field
                                      component={DatePickerFormik}
                                      name="calcificationDate"
                                      label={_t({ id: 'field.form.field.calcificationDate' })}
                                      format={'DD/MM/YYYY'}
                                      maxDate={moment()}
                                      clearable={true}
                                      readOnly={!values.calcification}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6} className={classes.item}>
                                  <Field
                                    component={TextField}
                                    name="calcificationQuantity"
                                    type="text"
                                    fullWidth
                                    inputProps={{
                                      id: 'calcificationQuantity',
                                      maxLength: 64,
                                      readOnly: !values.calcification,
                                    }}
                                    label={_t({ id: 'field.form.field.calcificationQuantity' })}
                                  />
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={6}>
                          <Card className={classes.card}>
                            <CardHeader className={classes.cardHeader}
                              avatar={<Icon path={mdiFlaskOutline} size="1.5rem" />}
                              title={_t({ id: 'field.form.section.plaster' })}
                            >
                            </CardHeader>
                            <CardContent className={classes.cardContent}>
                              <Grid container item xs={12}>
                                <Grid item xs={12} className={classes.item}>
                                  <FormControlLabel
                                    control={
                                      <Field
                                        component={Checkbox}
                                        name="plaster"
                                        type="checkbox"
                                        color="primary"
                                        inputProps={{
                                          id: 'plaster',
                                          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                            const checked = e.target.value === 'true';
                                            if (checked) {
                                              setFieldValue('plasterDate', null);
                                              setFieldValue('plasterQuantity', '');
                                            }
                                            setFieldValue('plaster', !checked);
                                          }
                                        }}
                                      />
                                    }
                                    label={_t({ id: 'field.form.field.plaster' })}
                                  />
                                </Grid>
                                <Grid item xs={6} className={classes.item}>
                                  <FormControl fullWidth error={!!errors.plasterDate}>
                                    <Field
                                      component={DatePickerFormik}
                                      name="plasterDate"
                                      label={_t({ id: 'field.form.field.plasterDate' })}
                                      format={'DD/MM/YYYY'}
                                      maxDate={moment()}
                                      clearable={true}
                                      readOnly={!values.plaster}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6} className={classes.item}>
                                  <Field
                                    component={TextField}
                                    name="plasterQuantity"
                                    type="text"
                                    fullWidth
                                    inputProps={{
                                      id: 'plasterQuantity',
                                      maxLength: 64,
                                      readOnly: !values.plaster,
                                    }}
                                    label={_t({ id: 'field.form.field.plasterQuantity' })}
                                  />
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>

                      <Card className={classes.card}>
                        <CardHeader className={classes.cardHeader}
                          avatar={<Icon path={mdiCommentTextOutline} size="1.5rem" />}
                          title={_t({ id: 'field.form.section.misc' })}
                        >
                        </CardHeader>
                        <CardContent className={classes.cardContent}>
                          <Grid container item xs={12}>
                            <Grid item xs={6} className={classes.item}>
                              <FormControlLabel
                                control={
                                  <Field
                                    component={Checkbox}
                                    name="invoice"
                                    type="checkbox"
                                    color="primary"
                                    inputProps={{
                                      id: 'invoice',
                                    }}
                                  />
                                }
                                label={_t({ id: 'field.form.field.invoice' })}
                              />
                            </Grid>
                            <Grid item xs={6} className={classes.item}>
                              <FormControlLabel
                                control={
                                  <Field
                                    component={Checkbox}
                                    name="businessPlan"
                                    type="checkbox"
                                    color="primary"
                                    inputProps={{
                                      id: 'businessPlan',
                                    }}
                                  />
                                }
                                label={_t({ id: 'field.form.field.businessPlan' })}
                              />
                            </Grid>
                            <Grid item xs={12} className={classes.item}>
                              <Field
                                component={TextField}
                                name="comment"
                                type="textarea"
                                fullWidth
                                inputProps={{
                                  id: 'comment',
                                }}
                                multiline
                                label={_t({ id: 'field.form.field.comment' })}
                              />
                            </Grid>
                            <Grid container item xs={12} justify="flex-end">
                              <Button
                                type="submit"
                                style={{ marginRight: 10 }}
                                variant="contained"
                                color="primary"
                                className={`${classes.button} mr-2`}
                                disabled={isSubmitting || loading}
                              >
                                <FormattedMessage id="view.shared.action.save" />
                              </Button>
                              <Button
                                type="button"
                                variant="contained"
                                className={classes.button}
                                onClick={() => this.showConfirmDialog()}
                                disabled={isSubmitting || loading}>
                                <FormattedMessage id="view.shared.action.cancel" />
                              </Button>
                            </Grid>
                            <SecureContent roles={[EnumRole.DEVELOPER]}>
                              <pre>{JSON.stringify(values, null, 2)}</pre>
                            </SecureContent>
                            <SecureContent roles={[EnumRole.DEVELOPER]}>
                              <pre>{JSON.stringify(errors, null, 2)}</pre>
                            </SecureContent>
                          </Grid>
                        </CardContent>
                      </Card>
                    </>
                  }
                </Form>
              )}
            </Formik>
          </MuiPickersUtilsProvider>
        </Grid>
        {this.renderConfirm()}
        <RouteGuard
          when={confirmOnNavigate}
          navigate={(location: string): void => this.props.history.push(location)}
        />
      </>
    );
  }

  renderConfirm() {
    const _t = this.props.intl.formatMessage;

    const { confirm } = this.state;

    if (!confirm) {
      return null;
    }

    return (
      <Dialog
        actions={[
          {
            key: EnumDialogAction.Yes,
            label: _t({ id: 'view.shared.action.yes' }),
            iconClass: () => (<Icon path={mdiTrashCan} size="1.5rem" />),
            color: 'primary',
          }, {
            key: EnumDialogAction.No,
            label: _t({ id: 'view.shared.action.no' }),
            iconClass: () => (<Icon path={mdiUndoVariant} size="1.5rem" />)
          }
        ]}
        handleClose={() => this.hideConfirmDialog()}
        handleAction={(action) => this.confirmDialogHandler(action)}
        header={
          <span>
            <i className={'mdi mdi-comment-question-outline mr-2'}></i>
            <FormattedMessage id="view.shared.dialog.title" />
          </span>
        }
        open={confirm}
      >
        <FormattedMessage id="view.shared.message.cancel-confirm" />
      </Dialog>
    );
  }
}

const mapState = (state: RootState) => ({
  config: state.config,
  loading: state.field.explorer.loading,
  lastUpdated: state.field.explorer.lastUpdated,
});

const mapDispatch = {
};

const connector = connect(
  mapState,
  mapDispatch,
);

type PropsFromRedux = ConnectedProps<typeof connector>

// Apply styles
const styledComponent = withStyles(styles)(FieldForm);

// Inject i18n resources
const localizedComponent = injectIntl(styledComponent);

// Inject state
export default connector(localizedComponent);
