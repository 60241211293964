import { AxiosRequestConfig } from 'axios';

import { Api } from 'utils/api';
import { ObjectResponse, PageRequest, Sorting, AxiosObjectResponse, AxiosPageResponse, PageResult, AxiosSimpleResponse } from 'model/response';
import { Organization, OrganizationQuery } from 'model/organization';

export default class OrganizationApi extends Api {

  constructor(config: AxiosRequestConfig = {}) {
    super(config);
  }

  public createNew(): Organization {
    return {
      name: '',
      description: '',
    };
  }

  public async find(query: Partial<OrganizationQuery>, pageRequest: PageRequest, sorting: Sorting[]): Promise<AxiosPageResponse<Organization>> {
    const { page, size } = pageRequest;
    const { id: field, order } = sorting[0];

    const queryString = (Object.keys(query) as Array<keyof OrganizationQuery>)
      .reduce((result: string[], key: keyof OrganizationQuery) => {
        return query[key] !== null ? [...result, `${key}=${query[key]}`] : result;
      }, []);

    const url = `/action/admin/organizations?page=${page}&size=${size}&${queryString.join('&')}&orderBy=${field}&order=${order}`;

    return this.get<ObjectResponse<PageResult<Organization>>>(url);
  }

  public async findOne(id: number): Promise<AxiosObjectResponse<Organization>> {
    const url = `/action/admin/organizations/${id}`;

    return this.get<ObjectResponse<Organization>>(url);
  }

  public async create(organization: Organization): Promise<AxiosObjectResponse<Organization>> {
    const url = `/action/admin/organizations/`;

    return this.post<Organization, ObjectResponse<Organization>>(url, organization);
  }

  public async update(id: number, organization: Organization): Promise<AxiosObjectResponse<Organization>> {
    const url = `/action/admin/organizations/${id}`;

    return this.post<Organization, ObjectResponse<Organization>>(url, organization);
  }

  public async remove(id: number): Promise<AxiosSimpleResponse> {
    const url = `/action/admin/organizations/${id}`;

    return this.delete(url);
  }


}
