import { PageResult, Sorting, ObjectResponse } from 'model/response';
import { Organization, OrganizationQuery } from 'model/organization';

import {
  OrganizationManagerActions,
  SET_PAGER,
  RESET_PAGER,
  SET_FILTER,
  RESET_FILTER,
  SEARCH_INIT,
  SEARCH_COMPLETE,
  ADD_SELECTED,
  REMOVE_SELECTED,
  SET_SORTING,
  RESET_SELECTED,
  SAVE_INIT,
  SAVE_COMPLETE,
} from './types';


// Action Creators
export function setPager(page: number, size: number): OrganizationManagerActions {
  return {
    type: SET_PAGER,
    page,
    size,
  };
}

export function resetPager(): OrganizationManagerActions {
  return {
    type: RESET_PAGER,
  };
}

export function setFilter(query: Partial<OrganizationQuery>): OrganizationManagerActions {
  return {
    type: SET_FILTER,
    query,
  };
}

export function resetFilter(): OrganizationManagerActions {
  return {
    type: RESET_FILTER,
  };
}

export function setSorting(sorting: Sorting[]): OrganizationManagerActions {
  return {
    type: SET_SORTING,
    sorting,
  };
}

export function searchInit(): OrganizationManagerActions {
  return {
    type: SEARCH_INIT,
  };
}

export function searchComplete(result: PageResult<Organization>): OrganizationManagerActions {
  return {
    type: SEARCH_COMPLETE,
    result,
  };
}

export function saveInit(): OrganizationManagerActions {
  return {
    type: SAVE_INIT,
  };
}

export function saveComplete(response: ObjectResponse<Organization>): OrganizationManagerActions {
  return {
    type: SAVE_COMPLETE,
    response,
  };
}

export function addToSelection(selected: Organization[]): OrganizationManagerActions {
  return {
    type: ADD_SELECTED,
    selected,
  };
}

export function removeFromSelection(removed: Organization[]): OrganizationManagerActions {
  return {
    type: REMOVE_SELECTED,
    removed,
  };
}

export function resetSelection(): OrganizationManagerActions {
  return {
    type: RESET_SELECTED,
  };
}
