import { IntlShape } from 'react-intl';

import { FormikErrors } from 'formik';

import { SampleCommand } from 'model/sample';


export type SampleErrors = FormikErrors<SampleCommand>;

export function sampleValidator(intl: IntlShape, values: SampleCommand): SampleErrors {
  const _t = intl.formatMessage;

  const errors: SampleErrors = {};

  if (!values.fieldId) {
    errors.fieldId = _t({ id: 'validation.required' });
  }
  if (values.longitude && (values.longitude < -180 || values.longitude > 180)) {
    errors.longitude = _t({ id: 'validation.out-of-range' }, { min: -180, max: 180 });
  }
  if (values.latitude && (values.latitude < -90 || values.latitude > 90)) {
    errors.latitude = _t({ id: 'validation.out-of-range' }, { min: -90, max: 90 });
  }
  if (!values.samplingDate) {
    errors.samplingDate = _t({ id: 'validation.required' });
  }
  if (!values.cultivation) {
    errors.cultivation = _t({ id: 'validation.required' });
  }
  return errors;
}
