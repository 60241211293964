import { IntlShape } from 'react-intl';

import { FormikErrors } from 'formik';

import { vatValidator } from 'utils/validation';

import { AccountCommand, SetPasswordCommand } from 'model/account';


//type DeepPartial<T> = T extends Array<infer R> ? string : T extends Function ? T : (T extends object ? { [P in keyof T]?: DeepPartial<T[P]>; } : T extends number ? string : T);

export type AccountErrors = FormikErrors<AccountCommand>;

export type PasswordErrors = FormikErrors<SetPasswordCommand>;

export function accountValidator(intl: IntlShape, values: AccountCommand): AccountErrors {
  const _t = intl.formatMessage;

  const errors: AccountErrors = {
    customer: { address: {} }
  };

  const customer = errors.customer;
  const address = customer?.address;

  // User
  if (!values.organizationId) {
    errors.organizationId = _t({ id: 'validation.required' });
  }
  if (!values.username) {
    errors.username = _t({ id: 'validation.required' });
  }
  if (!values.email) {
    errors.email = _t({ id: 'validation.required' });
  }
  if (!values.roles || values.roles.length === 0) {
    errors.roles = _t({ id: 'validation.required' });
  }
  if (!values.firstName) {
    errors.firstName = _t({ id: 'validation.required' });
  }
  if (!values.lastName) {
    errors.lastName = _t({ id: 'validation.required' });
  }
  if (values.id) {
    if (values.password) {
      if (!values.passwordMatch) {
        errors.passwordMatch = _t({ id: 'validation.required' });
      } if (values.password !== values.passwordMatch) {
        errors.passwordMatch = _t(
          { id: 'validation.mismatch' }, {
          field1: _t({ id: 'account.form.field.password' }),
          field2: _t({ id: 'account.form.field.passwordMatch' })
        });
      }
    } else if (values.passwordMatch) {
      if (!values.password) {
        errors.password = _t({ id: 'validation.required' });
      } if (values.password !== values.passwordMatch) {
        errors.passwordMatch = _t(
          { id: 'validation.mismatch' }, {
          field1: _t({ id: 'account.form.field.password' }),
          field2: _t({ id: 'account.form.field.passwordMatch' })
        });
      }
    }
  } else {
    if (!values.password) {
      errors.password = _t({ id: 'validation.required' });
    } else if (!values.passwordMatch) {
      errors.passwordMatch = _t({ id: 'validation.required' });
    } if (values.password !== values.passwordMatch) {
      errors.passwordMatch = _t(
        { id: 'validation.mismatch' }, {
        field1: _t({ id: 'account.form.field.password' }),
        field2: _t({ id: 'account.form.field.passwordMatch' })
      });
    }
  }

  // Address
  if (address && !values.customer?.address?.streetName) {
    address.streetName = _t({ id: 'validation.required' });
  }
  if (address && !values.customer?.address?.streetNumber) {
    address.streetNumber = _t({ id: 'validation.required' });
  }
  if (address && !values.customer?.address?.prefecture) {
    address.prefecture = _t({ id: 'validation.required' });
  }
  if (address && !values.customer?.address?.region) {
    address.region = _t({ id: 'validation.required' });
  }

  // Customer
  if (customer) {
    if (!values.customer?.name) {
      customer.name = _t({ id: 'validation.required' });
    }
    if (!values.customer?.vat) {
      customer.vat = _t({ id: 'validation.required' });
    } else if (!vatValidator(values.customer?.vat)) {
      customer.vat = _t({ id: 'validation.invalid' });
    }
    if (!values.customer?.taxOffice) {
      customer.taxOffice = _t({ id: 'validation.required' });
    }
    if (!values.customer?.occupation) {
      customer.occupation = _t({ id: 'validation.required' });
    }
    if (!values.customer?.phone) {
      customer.phone = _t({ id: 'validation.required' });
    }
  }
  // If no new keys are added except for customer and address, return an empty errors object
  if (Object.keys(errors).length === 1 && customer && Object.keys(customer).length === 1 && address && Object.keys(address).length === 0) {
    return {};
  }
  return errors;
}

export function passwordValidator(intl: IntlShape, values: SetPasswordCommand): PasswordErrors {
  const _t = intl.formatMessage;

  const errors: FormikErrors<SetPasswordCommand> = {};

  if (values.password) {
    if (!values.passwordMatch) {
      errors.passwordMatch = _t({ id: 'validation.required' });
    } if (values.password !== values.passwordMatch) {
      errors.passwordMatch = _t(
        { id: 'validation.mismatch' }, {
        field1: _t({ id: 'account.form.field.password' }),
        field2: _t({ id: 'account.form.field.passwordMatch' })
      });
    }
  }

  return errors;
}