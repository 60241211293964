import React from 'react';

// Localization
import { injectIntl, IntlShape } from 'react-intl';

// Material UI
import { createStyles, WithStyles } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({
  root: {
    padding: 5,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing(3, 0, 2, 2),
    borderRadius: 0,
    textTransform: 'none',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    maxWidth: 150,
  },
});

interface TabPanelProps extends WithStyles<typeof styles> {
  intl: IntlShape,
  children?: React.ReactNode;
  index: any;
  value: any;
}

class TabPanel extends React.Component<TabPanelProps> {

  render() {
    const { children, value, index, ...other } = this.props;

    if (value !== index) {
      return null;
    }

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {children}
      </div >
    );
  }
}

// Apply styles
const styledComponent = withStyles(styles)(TabPanel);

// Inject i18n resources
const localizedComponent = injectIntl(styledComponent);

export default localizedComponent;

