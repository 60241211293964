import { PageResult, Sorting, ObjectResponse } from 'model/response';
import { Field, FieldQuery } from 'model/field';

import {
  FieldActions,
  SET_PAGER,
  RESET_PAGER,
  SET_FILTER,
  RESET_FILTER,
  SEARCH_INIT,
  SEARCH_COMPLETE,
  ADD_SELECTED,
  REMOVE_SELECTED,
  SET_SORTING,
  RESET_SELECTED,
  SAVE_INIT,
  SAVE_COMPLETE,
} from './types';


// Action Creators
export function setPager(page: number, size: number): FieldActions {
  return {
    type: SET_PAGER,
    page,
    size,
  };
}

export function resetPager(): FieldActions {
  return {
    type: RESET_PAGER,
  };
}

export function setFilter(query: Partial<FieldQuery>): FieldActions {
  return {
    type: SET_FILTER,
    query,
  };
}

export function resetFilter(): FieldActions {
  return {
    type: RESET_FILTER,
  };
}

export function setSorting(sorting: Sorting[]): FieldActions {
  return {
    type: SET_SORTING,
    sorting,
  };
}

export function searchInit(): FieldActions {
  return {
    type: SEARCH_INIT,
  };
}

export function searchComplete(result: PageResult<Field>): FieldActions {
  return {
    type: SEARCH_COMPLETE,
    result,
  };
}

export function saveInit(): FieldActions {
  return {
    type: SAVE_INIT,
  };
}

export function saveComplete(response: ObjectResponse<Field>): FieldActions {
  return {
    type: SAVE_COMPLETE,
    response,
  };
}

export function addToSelection(selected: Field[]): FieldActions {
  return {
    type: ADD_SELECTED,
    selected,
  };
}

export function removeFromSelection(removed: Field[]): FieldActions {
  return {
    type: REMOVE_SELECTED,
    removed,
  };
}

export function resetSelection(): FieldActions {
  return {
    type: RESET_SELECTED,
  };
}
