import _ from 'lodash';
import React from 'react';

import { connect, ConnectedProps } from 'react-redux';
import { injectIntl, IntlShape } from 'react-intl';

// Material UI
import { createStyles, WithStyles } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';

import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';

// Icons
import Icon from '@mdi/react';
import {
  mdiCheckerboard,
  mdiLayersOutline,
} from '@mdi/js';

// Store
import { RootState } from 'store';
import { toggleLayer } from 'store/map/actions';

// Model
import { WmsParameters } from 'model/configuration';

const styles = (theme: Theme) => createStyles({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  childMenu: {
    borderRight: '3px solid #3f51b5',
  }
});

interface MapViewerConfigState {
  layerGroups: { [index: string]: WmsParameters[] },
  open: {
    [index: string]: boolean;
  },
}

interface MapViewerConfigProps extends PropsFromRedux, WithStyles<typeof styles> {
  intl: IntlShape,
}

class MapViewerConfig extends React.Component<MapViewerConfigProps, MapViewerConfigState> {

  state: MapViewerConfigState = {
    layerGroups: {},
    open: {},
  }

  onSectionToggle(name: string) {
    this.setState({
      ...this.state,
      open: {
        ...this.state.open,
        [name]: !this.state.open[name],
      }
    });
  }

  onLayerToggle(index: number): void {
    this.props.toggleLayer(index);
  }

  componentDidMount() {
    const { config: { wmsLayers: layers } } = this.props;

    const layerGroups: { [index: string]: WmsParameters[] } = {};

    _.sortBy(layers, ['index', 'name']).forEach((l: WmsParameters) => {
      if (!layerGroups[l.category]) {
        layerGroups[l.category] = [];
      }
      layerGroups[l.category].push(l);
    });

    this.setState({
      layerGroups,
    });
  }

  render() {
    const { layerGroups, open } = this.state;
    const { map: { selected } } = this.props;

    const layers = layerGroups[''] || [];
    const groups = Object.keys(layerGroups).filter(k => !!k);
    return (
      <div>
        <List>
          {layers.map((params: WmsParameters) => (
            <ListItem key={`layer-${params.index}`}>
              <ListItemIcon>
                <Icon path={mdiCheckerboard} size="1.5rem" />
              </ListItemIcon>
              <ListItemText primary={params.name} />
              <ListItemSecondaryAction>
                <Checkbox
                  edge="end"
                  onChange={() => this.onLayerToggle(params.index)}
                  checked={selected.includes(params.index)}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
          {groups.map((name: string) => {
            return (
              <React.Fragment key={`layer-group-${name}`}>
                <ListItem button onClick={() => this.onSectionToggle(name)}>
                  <ListItemIcon>
                    <Icon path={mdiLayersOutline} size="1.5rem" />
                  </ListItemIcon>
                  <ListItemText primary={name} />
                  {open[name] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>

                <Collapse in={open[name]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>

                    {layerGroups[name].map((params: WmsParameters) => (
                      <ListItem key={`layer-${params.index}`}>
                        <ListItemIcon>
                          <Icon path={mdiCheckerboard} size="1.5rem" />
                        </ListItemIcon>
                        <ListItemText primary={params.name} />
                        <ListItemSecondaryAction>
                          <Checkbox
                            edge="end"
                            onChange={() => this.onLayerToggle(params.index)}
                            checked={selected.includes(params.index)}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </React.Fragment>
            );
          })}
        </List>
      </div >
    );
  }
}

const mapState = (state: RootState) => ({
  config: state.config,
  map: state.map,
  profile: state.security.profile,
});

const mapDispatch = {
  toggleLayer,
};


const connector = connect(
  mapState,
  mapDispatch,
);

type PropsFromRedux = ConnectedProps<typeof connector>;

// Apply styles
const styledComponent = withStyles(styles)(MapViewerConfig);

// Inject i18n resources
const localizedComponent = injectIntl(styledComponent);

// Inject state
export default connector(localizedComponent);
