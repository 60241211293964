import { ThunkAction } from 'redux-thunk'

// Redux
import { RootState } from 'store';
import { OrganizationManagerActions } from './types';
import { searchInit, searchComplete, setSorting, setPager, saveInit, saveComplete } from './actions';

// Services
import OrganizationApi from 'service/organization';

// Model
import { PageRequest, Sorting, PageResult, ObjectResponse } from 'model/response';
import { Organization } from 'model/organization';

// Helper thunk result type
type ThunkResult<R> = ThunkAction<Promise<R>, RootState, unknown, OrganizationManagerActions>;

export const find = (
  pageRequest?: PageRequest, sorting?: Sorting[]
): ThunkResult<PageResult<Organization>> => async (dispatch, getState) => {
  // Get query form state (filters are always set synchronously)
  const query = getState().organization.explorer.query;

  // Update sorting or use the existing value
  if (sorting) {
    dispatch(setSorting(sorting));
  } else {
    sorting = getState().organization.explorer.sorting;
  }

  // Update page or user the existing value (i.e. data page refresh)
  if (pageRequest) {
    const { page, size } = pageRequest;

    dispatch(setPager(page, size));
  } else {
    pageRequest = getState().organization.explorer.pagination
  }

  // Initialize search
  dispatch(searchInit());

  // Get response
  const api = new OrganizationApi();

  const response = await api.find(query, pageRequest, sorting);

  // Update state
  dispatch(searchComplete(response.data.result));

  return response.data.result;
}

export const update = (
  id: number | null, organization: Organization
): ThunkResult<ObjectResponse<Organization>> => async (dispatch, getState) => {
  dispatch(saveInit());

  // Get response
  const api = new OrganizationApi();

  const response = id ? await api.update(id, organization) : await api.create(organization);

  // Update state
  dispatch(saveComplete(response.data));

  return response.data;
}
