import { AxiosRequestConfig } from 'axios';

import { Api } from 'utils/api';
import { ObjectResponse, PageRequest, Sorting, AxiosObjectResponse, AxiosPageResponse, PageResult, AxiosSimpleResponse } from 'model/response';
import { Field, FieldQuery, FieldFormData, FieldCommand } from 'model/field';
import { CustomerBase } from 'model/customer';

export default class FieldApi extends Api {

  constructor(config: AxiosRequestConfig = {}) {
    super(config);
  }

  public createNew(customerId: number | null = null): FieldCommand {
    return {
      businessPlan: false,
      calcification: false,
      calcificationDate: null,
      calcificationQuantity: '',
      comment: '',
      createdAt: null,
      customerId,
      geometry: null,
      invoice: false,
      manure: false,
      manureDate: null,
      manureQuantity: '',
      modifiedAt: null,
      municipality: '',
      municipalUnity: null,
      name: '',
      organicMaterial: false,
      organicMaterialDate: null,
      organicMaterialQuantity: '',
      plaster: false,
      plasterDate: null,
      plasterQuantity: '',
      prefecture: null,
      region: '',
      size: null,
    };
  }

  public async findCustomerById(id: number): Promise<AxiosObjectResponse<CustomerBase | null>> {
    const url = `/action/fields/customer/${id}`;

    return this.get<ObjectResponse<CustomerBase | null>>(url);
  }

  public async findCustomers(): Promise<AxiosObjectResponse<CustomerBase[]>> {
    const url = `/action/fields/customers`;

    return this.get<ObjectResponse<CustomerBase[]>>(url);
  }

  public async find(query: Partial<FieldQuery>, pageRequest: PageRequest, sorting: Sorting[]): Promise<AxiosPageResponse<Field>> {
    const { page, size } = pageRequest;
    const { id: field, order } = sorting[0];

    const queryString = (Object.keys(query) as Array<keyof FieldQuery>)
      .reduce((result: string[], key: keyof FieldQuery) => {
        return query[key] !== null ? [...result, `${key}=${query[key]}`] : result;
      }, []);

    const url = `/action/fields?page=${page}&size=${size}&${queryString.join('&')}&orderBy=${field}&order=${order}`;

    return this.get<ObjectResponse<PageResult<Field>>>(url);
  }

  public async findOne(id: number): Promise<AxiosObjectResponse<FieldFormData>> {
    const url = `/action/fields/${id}`;


    return this.get<ObjectResponse<FieldFormData>>(url);
  }

  public async create(command: FieldCommand): Promise<AxiosObjectResponse<Field>> {
    const url = `/action/fields/`;

    return this.post<FieldCommand, ObjectResponse<Field>>(url, command);
  }

  public async update(id: number, command: FieldCommand): Promise<AxiosObjectResponse<Field>> {
    const url = `/action/fields/${id}`;

    return this.post<FieldCommand, ObjectResponse<Field>>(url, command);
  }

  public async remove(id: number): Promise<AxiosSimpleResponse> {
    const url = `/action/fields/${id}`;

    return this.delete(url);
  }

  public fieldToCommand(field: Field): FieldCommand {
    const { customer, prefecture, municipalUnity, ...rest } = field;

    const command: FieldCommand = {
      ...rest,
      customerId: customer?.id || null,
      municipalUnity: municipalUnity?.id || null,
      prefecture: prefecture?.id || null,
    };

    return command;
  }
}
