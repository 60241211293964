import React from 'react';

class PlaceHolder extends React.Component<{}> {

  render() {
    return null
  }

}

export default PlaceHolder;