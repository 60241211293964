import React from 'react';
import { AxiosError } from 'axios';

// State, routing and localization
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

// 3rd party components
import { createStyles, WithStyles } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { Field, Form, Formik } from 'formik';

import { TextField } from 'formik-material-ui';

import Icon from '@mdi/react';
import { mdiTrashCan, mdiUndoVariant, mdiCommentAlertOutline } from '@mdi/js';

// Utilities
import { localizeErrorCodes } from 'utils/error';

// Services
import message from 'service/message';
import OrganizationApi from 'service/organization';

// Store
import { RootState } from 'store';

// Components
import SecureContent from 'components/secure-content';
import Dialog, { DialogAction, EnumDialogAction } from 'components/dialog';
import RouteGuard from 'components/route-guard';

// Model
import { FieldMapperFunc } from 'utils/error';

import { StaticRoutes } from 'model/routes';
import { EnumRole } from 'model/role';
import { AxiosObjectResponse, SimpleResponse } from 'model/response';
import { Organization } from 'model/organization';

const fieldMapper: FieldMapperFunc = (field: string): string | null => {
  switch (field) {
    case 'name':
      return 'organization.form.field.name';
  }
  return null;
};

type SetFieldValue = (field: string, value: any, shouldValidate?: boolean) => void;

const styles = (theme: Theme) => createStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    borderRadius: 0,
    width: '100%',
    maxWidth: '1024px',
  },
  item: {
    padding: 8,
  },
  button: {
    margin: theme.spacing(3, 1, 2),
    borderRadius: 0,
    textTransform: 'none',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
});

interface OrganizationState {
  confirm: boolean;
  confirmOnNavigate: boolean;
  organization: Organization | null;
}

interface RouteParams {
  id?: string | undefined;
}

interface OrganizationFormProps extends PropsFromRedux, WithStyles<typeof styles>, RouteComponentProps<RouteParams> {
  intl: IntlShape,
}

class OrganizationForm extends React.Component<OrganizationFormProps, OrganizationState> {

  private api: OrganizationApi;

  constructor(props: OrganizationFormProps) {
    super(props);

    this.api = new OrganizationApi();
  }

  state: OrganizationState = {
    confirm: false,
    confirmOnNavigate: true,
    organization: null,
  }

  get id(): number | null {
    const { id } = this.props.match.params;

    if (!id) {
      return null;
    }

    return Number.parseInt(id);
  }

  discardChanges(): void {
    this.setState({
      confirmOnNavigate: false,
    }, () => this.props.history.push(StaticRoutes.OrganizationManager));
  }

  showConfirmDialog(): void {
    this.setState({
      confirm: true,
    });
  }

  hideConfirmDialog(): void {
    this.setState({
      confirm: false,
    });
  }

  confirmDialogHandler(action: DialogAction): void {
    switch (action.key) {
      case EnumDialogAction.Yes:
        this.discardChanges();
        break;
      default:
        this.hideConfirmDialog();
        break;
    }
  }

  componentDidMount() {
    const id = this.id;

    if (id) {
      this.api.findOne(id).then((response: AxiosObjectResponse<Organization>) => {
        if (response.data.success) {
          this.setState({
            organization: response.data.result,
          });
        } else {
          const messages = localizeErrorCodes(this.props.intl, response.data, false);
          message.errorHtml(messages, () => (<Icon path={mdiCommentAlertOutline} size="3rem" />));

          this.props.history.push(StaticRoutes.OrganizationManager);
        }
      });
    } else {
      this.setState({
        organization: this.api.createNew(),
      });
    }
  }

  render() {
    const _t = this.props.intl.formatMessage;

    const { classes, loading } = this.props;
    const { confirmOnNavigate, organization: o } = this.state;

    if (!o) {
      return null;
    }

    return (
      <>
        <Grid container item xs={12} justify="center">
          <Paper className={classes.paper}>
            <Formik
              initialValues={{
                ...o,
              }}
              validate={values => {
                const errors: Partial<Organization> = {};

                if (!values.name) {
                  errors.name = _t({ id: 'validation.required' });
                }

                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                const id = this.id;

                (id === null ? this.api.create(values) : this.api.update(id, values))
                  .then((response) => {
                    if (response.data.success) {
                      this.discardChanges();
                    } else {
                      const messages = localizeErrorCodes(this.props.intl, response.data, true, fieldMapper);
                      message.errorHtml(messages, () => (<Icon path={mdiCommentAlertOutline} size="3rem" />), 10000);
                    }
                  })
                  .catch((err: AxiosError<SimpleResponse>) => {
                    const messages = localizeErrorCodes(this.props.intl, err.response?.data, true, fieldMapper);
                    message.errorHtml(messages, () => (<Icon path={mdiCommentAlertOutline} size="3rem" />), 10000);
                  })
                  .finally(() => {
                    setSubmitting(false);
                  });
              }}
            >
              {({ isSubmitting, values, setFieldValue, errors }) => (
                <Form>
                  <Grid container item xs={12}>
                    <Grid container item xs={12}>
                      <Grid item xs={4} className={classes.item}>
                        <Field
                          component={TextField}
                          id="name"
                          name="name"
                          type="text"
                          label={_t({ id: 'organization.form.field.name' })}
                        />
                      </Grid>
                      <Grid item xs={8} className={classes.item}>
                        <Field
                          component={TextField}
                          id="description"
                          name="description"
                          type="text"
                          label={_t({ id: 'organization.form.field.description' })}
                          fullWidth
                        />
                      </Grid>
                      <Grid container item xs={12} justify="flex-end">
                        <Button
                          type="submit"
                          style={{ marginRight: 10 }}
                          variant="contained"
                          color="primary"
                          className={`${classes.button} mr-2`}
                          disabled={isSubmitting || loading}
                        >
                          <FormattedMessage id="view.shared.action.save" />
                        </Button>
                        <Button
                          type="button"
                          variant="contained"
                          className={classes.button}
                          onClick={() => this.showConfirmDialog()}
                          disabled={isSubmitting || loading}>
                          <FormattedMessage id="view.shared.action.cancel" />
                        </Button>
                      </Grid>
                    </Grid>
                    <SecureContent roles={[EnumRole.DEVELOPER]}>
                      <pre>{JSON.stringify(values, null, 2)}</pre>
                    </SecureContent>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Paper>
        </Grid>
        {this.renderConfirm()}
        <RouteGuard
          when={confirmOnNavigate}
          navigate={(location: string): void => this.props.history.push(location)}
        />
      </>
    );
  }

  renderConfirm() {
    const _t = this.props.intl.formatMessage;

    const { confirm } = this.state;

    if (!confirm) {
      return null;
    }

    return (
      <Dialog
        actions={[
          {
            key: EnumDialogAction.Yes,
            label: _t({ id: 'view.shared.action.yes' }),
            iconClass: () => (<Icon path={mdiTrashCan} size="1.5rem" />),
            color: 'primary',
          }, {
            key: EnumDialogAction.No,
            label: _t({ id: 'view.shared.action.no' }),
            iconClass: () => (<Icon path={mdiUndoVariant} size="1.5rem" />)
          }
        ]}
        handleClose={() => this.hideConfirmDialog()}
        handleAction={(action) => this.confirmDialogHandler(action)}
        header={
          <span>
            <i className={'mdi mdi-comment-question-outline mr-2'}></i>
            <FormattedMessage id="view.shared.dialog.title" />
          </span>
        }
        open={confirm}
      >
        <FormattedMessage id="view.shared.message.cancel-confirm" />
      </Dialog>
    );
  }
}

const mapState = (state: RootState) => ({
  config: state.config,
  loading: state.organization.explorer.loading,
  lastUpdated: state.organization.explorer.lastUpdated,
});

const mapDispatch = {
};

const connector = connect(
  mapState,
  mapDispatch,
);

type PropsFromRedux = ConnectedProps<typeof connector>

// Apply styles
const styledComponent = withStyles(styles)(OrganizationForm);

// Inject i18n resources
const localizedComponent = injectIntl(styledComponent);

// Inject state
export default connector(localizedComponent);
